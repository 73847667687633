import { captureException } from '@sentry/nextjs';

import {
  authAxios,
  authAxiosWithoutChannelUid,
  studioPublicAxiosV1,
} from 'lib/axios';

import type { CommentPageProps } from 'types/comment';
import type {
  MatchImageType,
  MatchLink,
  MatchPlayer,
  ResultPagesType,
  ServerMatchDetailDataType,
  SeverMatchUpdateDataType,
} from 'types/matchData';

export const getServerMatchDataList = async (queries: string) =>
  await studioPublicAxiosV1<ResultPagesType>({
    method: 'GET',
    url: `/scoreboard/${queries}`,
  });

export const getStaffPickDataList = async (queries: string) =>
  await studioPublicAxiosV1<ResultPagesType>({
    method: 'GET',
    url: `/scoreboard/staffpick/${queries}`,
  });

export const getRecommendMatchList = async (queries: string) =>
  await studioPublicAxiosV1<ResultPagesType>({
    method: 'GET',
    url: `/scoreboard/recommend${queries}`,
  });

export const getScheduledMatchList = async (queries: string) =>
  await studioPublicAxiosV1<ResultPagesType>({
    method: 'GET',
    url: `/scoreboard/scheduled/${queries}`,
  });

export const getAllMatchList = async (queries: string) =>
  await studioPublicAxiosV1<ResultPagesType>({
    method: 'GET',
    url: `/scoreboard/list/all${queries}`,
  });

export const getInterestMatchList = async (queries: string) =>
  await studioPublicAxiosV1<ResultPagesType>({
    method: 'GET',
    url: `/scoreboard/list/interest${queries}`,
  });

export const getLiveMatchList = async (queries: string) =>
  await studioPublicAxiosV1<ResultPagesType>({
    method: 'GET',
    url: `/scoreboard/list/live${queries}`,
  });

export const getNewMatchList = async (queries: string) =>
  await studioPublicAxiosV1<ResultPagesType>({
    method: 'GET',
    url: `/scoreboard/list/new${queries}`,
  });

export const getServerMatchData = async (matchId: string) =>
  await studioPublicAxiosV1<ServerMatchDetailDataType>({
    method: 'GET',
    url: `/scoreboard/${matchId}`,
  });

export const updateServerMatchDataWithChannelUid = async (
  token: string,
  matchId: string,
  updatedData: {
    awayTeamId?: number | null;
    homeTeamId?: number | null;
    updateMatchInfo?: SeverMatchUpdateDataType;
    createPlayerInfo?: MatchPlayer[];
    updatePlayerInfo?: MatchPlayer[];
    deletePlayerInfo?: number[];
    addImageInfo?: MatchImageType[];
    deleteImageInfo?: number[];
    addLinksInfo?: MatchLink[];
    deleteLinksInfo?: number[];
    addLeaderboardIds?: number[];
    deleteLeaderboardIds?: number[];
    setActualStartTime?: [{ id: number; actualStartTime: number }];
  },
  manageChannelUid?: string,
): Promise<{ matchInfo: ServerMatchDetailDataType }> => {
  try {
    if (manageChannelUid)
      return await authAxios({
        token,
        serverType: 'studio',
        version: 'v1',
        channelUid: manageChannelUid,
      })({
        method: 'PATCH',
        url: `/scoreboard/${matchId}`,
        data: updatedData,
      });
    else
      return await authAxiosWithoutChannelUid({
        token,
        serverType: 'studio',
        version: 'v1',
      })({
        method: 'PATCH',
        url: `/scoreboard/${matchId}`,
        data: updatedData,
      });
  } catch (error) {
    captureException(`updateServerMatchDataWithChannelUid 에러: ${error}`);
    throw error;
  }
};

export const getComment = async (
  matchId: string,
  limit: number,
  offset: number,
) =>
  await studioPublicAxiosV1<CommentPageProps>({
    method: 'GET',
    url: `/scoreboard/comment/${matchId}?limit=${limit}&offset=${offset ?? 0}`,
  });

export const updateVideoLinkInfo = async (
  videoInfo: {
    matchId: number;
    mainLink: string;
    isExistThumbnail: boolean;
    canPlay: boolean;
    linkThumbnailUrl: string | null;
  },
  channelUid?: string,
) => {
  if (process.env.NEXT_PUBLIC_CAMERAFI_STUDIO_API_KEY) {
    if (channelUid) {
      await authAxios({
        serverType: 'studio',
        token: process.env.NEXT_PUBLIC_CAMERAFI_STUDIO_API_KEY,
        version: 'v1',
        channelUid,
      })({
        method: 'PATCH',
        url: `/scoreboard/linkInfo`,
        data: videoInfo,
      });
    } else {
      await authAxiosWithoutChannelUid({
        serverType: 'studio',
        token: process.env.NEXT_PUBLIC_CAMERAFI_STUDIO_API_KEY,
        version: 'v1',
      })({
        method: 'PATCH',
        url: `/scoreboard/linkInfo`,
        data: videoInfo,
      });
    }
  }
};

export const increaseMatchViewCount = async (matchId: string) =>
  await studioPublicAxiosV1({
    method: 'PATCH',
    url: `/scoreboard/views/${matchId}`,
  });
