import type {
  ButtonClasses,
  Theme} from '@mui/material';
import {
  type ButtonProps,
  type ButtonPropsColorOverrides,
  type ButtonPropsVariantOverrides,
  type PaletteMode
} from '@mui/material';
import type { OverridesStyleRules } from '@mui/material/styles/overrides';
import type { CSSProperties } from '@mui/styles';
import type { OverridableStringUnion } from '@mui/types';

import {
  BLACK,
  WHITE,
  gradient,
  grayPalette,
  magentaPalette,
  primaryPalette,
} from './palette';
import { typography } from './typography';

interface OwnerStateType {
  variant?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;
  color?: OverridableStringUnion<
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning',
    ButtonPropsColorOverrides
  >;
  size?: 'small' | 'medium' | 'large';
}

type CustomButtonStyleReturnType = {
  defaultProps?: Partial<ButtonProps>;
  styleOverrides?: Partial<
    OverridesStyleRules<
      keyof ButtonClasses,
      'MuiButton',
      Omit<Theme, 'components'>
    >
  >;
  variants?: {
    props: OwnerStateType;
    style: CSSProperties;
  }[];
};

const getButtonStyle = (mode: PaletteMode): CustomButtonStyleReturnType => ({
  styleOverrides: {
    root: ({ ownerState }: { ownerState: OwnerStateType }) => ({
      textTransform: 'none' as const,
      boxShadow: 'none',
      borderRadius: '4px',
      whiteSpace: 'nowrap',
      position: 'relative',

      ...getButtonTypographyStyle(ownerState.variant, ownerState.size),
      padding: getButtonPaddingStyle(ownerState.variant, ownerState.size),
      height: getButtonHeightStyle(ownerState.variant, ownerState.size),

      '&:hover': {
        boxShadow: 'none',
        ...getButtonHoverStyle(mode, ownerState.variant),
      },
      '&:disabled': {
        ...getButtonDisabledStyle(mode, ownerState.variant),
      },
    }),
    iconSizeSmall: {
      svg: {
        width: '18px',
        height: '18px',
      },
    },
    iconSizeMedium: {
      svg: {
        width: '20px',
        height: '20px',
      },
    },
    iconSizeLarge: {
      svg: {
        width: '24px',
        height: '24px',
      },
    },
  },
  variants: [
    {
      props: { variant: 'contained' },
      style: {
        color: WHITE,
      },
    },
    // ! TODO 세미나쪽 살피고 제거해야함 참고 (CS24-1580, CS24-339)
    {
      props: {
        variant: 'contained',
        color: 'inherit',
      },
      style: {
        backgroundColor: '#ededed',
        '&:hover': {
          backgroundColor: '#cfcfcf',
        },
      },
    },
    {
      props: { variant: 'gradient' },
      style: {
        background: gradient.primary,
        color: WHITE,
      },
    },
    {
      props: { variant: 'solid' },
      style: {
        backgroundColor: primaryPalette[500],
        color: WHITE,

        '&:hover': {
          backgroundColor: primaryPalette[500],
        },
      },
    },
    {
      props: { variant: 'solidSecondary' },
      style: {
        backgroundColor: mode === 'light' ? primaryPalette[50] : '#CEF2D5',
        border: `1px solid`,
        borderColor: mode === 'light' ? '#ADE8BA' : '#9AD9A8',
        color:
          mode === 'light' ? grayPalette[mode][800] : grayPalette[mode][100],

        '&:hover': {
          backgroundColor: mode === 'light' ? primaryPalette[50] : '#CEF2D5',
        },
      },
    },
    {
      props: { variant: 'outlinedPrimary' },
      style: {
        backgroundColor: mode === 'light' ? WHITE : BLACK,
        border: `1px solid`,
        borderColor:
          mode === 'light' ? grayPalette[mode][200] : grayPalette[mode][300],
        color: primaryPalette[500],

        '&:hover': {
          backgroundColor: mode === 'light' ? WHITE : grayPalette[mode][300],
        },
      },
    },
    {
      props: { variant: 'outlinedAssistive' },
      style: {
        backgroundColor: mode === 'light' ? WHITE : BLACK,
        border: `1px solid`,
        borderColor:
          mode === 'light' ? grayPalette[mode][200] : grayPalette[mode][300],
        color: mode === 'light' ? grayPalette[mode][700] : WHITE,

        '&:hover': {
          backgroundColor: mode === 'light' ? WHITE : grayPalette[mode][50],
        },
      },
    },
    {
      props: { variant: 'outlinedDelete' },
      style: {
        backgroundColor: mode === 'light' ? WHITE : BLACK,
        border: `1px solid`,
        borderColor:
          mode === 'light' ? magentaPalette[600] : magentaPalette[300],
        color: mode === 'light' ? magentaPalette[600] : magentaPalette[300],

        '&:hover': {
          backgroundColor: mode === 'light' ? WHITE : BLACK,
        },
      },
    },
    {
      props: { variant: 'textPrimary' },
      style: {
        color: primaryPalette[500],

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    {
      props: { variant: 'textAssistive' },
      style: {
        color: grayPalette[mode][400],

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    {
      props: { variant: 'solidDimmed' },
      style: {
        backgroundColor:
          mode === 'light' ? 'grayPalette.800' : 'grayPalette.200',
        color: WHITE,
      },
    },
  ],
});

// ? INFO
// ? 바로 변경하지않고 이렇게 함수를 선언해서 사용하는 이유는 기존에 적용된 버튼 스타일을 변경시키지 않기 위함
// ? 따라서 기존에 있는 모든 버튼 스타일을 새롭게 바꾸면 root에 설정하여 전체적으로 적용하는 개편이 필요함
// ? 원래는 이런 함수를 선언해서 size를 인자로 받아서 적용하는 방식이 아니라, styleOverrides안에 sizeSmall, sizeMedium, sizeLarge를 선언해서 적용하는 방식이 정석.
const isOriginVariantKey = (value?: string) =>
  ['text', 'outlined', 'contained'].includes(value || '');

const getButtonPaddingStyle = (
  variant?: OwnerStateType['variant'],
  size?: 'small' | 'medium' | 'large',
) => {
  if (isOriginVariantKey(variant))
    return {
      small: '4px 10px',
      medium: '6px 16px',
      large: '8px 22px',
    }[size || 'medium'];

  if (variant === 'textAssistive' || variant === 'textPrimary')
    return {
      small: '5px 4px',
      medium: '5.5px 4px',
      large: '6px 4px',
    }[size || 'medium'];

  return {
    small: '8px 25px',
    medium: '10px 33px',
    large: '12px 40px',
  }[size || 'medium'];
};

const getButtonHeightStyle = (
  variant: OwnerStateType['variant'],
  size?: 'small' | 'medium' | 'large',
) => {
  if (isOriginVariantKey(variant))
    return {
      small: '32px',
      medium: '40px',
      large: '46px',
    }[size || 'medium'];

  if (variant === 'textAssistive' || variant === 'textPrimary')
    return {
      small: '28px',
      medium: '32px',
      large: '36px',
    }[size || 'medium'];

  return {
    small: '34px',
    medium: '40px',
    large: '48px',
  }[size || 'medium'];
};

const getButtonHoverStyle = (
  mode: PaletteMode,
  variant: OwnerStateType['variant'],
) => {
  if (isOriginVariantKey(variant)) return undefined;

  const defaultStyle = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#000000',
  };

  switch (variant) {
    case 'gradient':
    case 'solid':
      return {
        '::before': {
          ...defaultStyle,
          backgroundColor: '#E5E5E9',
          opacity: 0.1,
        },
      };
    case 'solidDimmed':
      return {
        backgroundColor:
          mode === 'light' ? grayPalette[mode][700] : grayPalette[mode][300],
        '::before': {
          ...defaultStyle,
          backgroundColor: '#FFFFFF',
          opacity: 0.05,
        },
      };
    case 'solidSecondary':
    case 'outlinedPrimary':
    case 'outlinedAssistive':
    case 'outlinedDelete':
    case 'textPrimary':
    case 'textAssistive':
      return {
        '::before': {
          ...defaultStyle,
          opacity: 0.05,
        },
      };
    default:
      return undefined;
  }
};

const getButtonDisabledStyle = (
  mode: PaletteMode,
  variant?: OwnerStateType['variant'],
) => {
  const defaultStyle = {
    backgroundColor: mode === 'light' ? grayPalette[mode][100] : BLACK,
    border: mode === 'light' ? 'none' : '1px solid',
    borderColor: grayPalette[mode][100],
    color: grayPalette[mode][200],
  };

  if (isOriginVariantKey(variant)) return undefined;

  switch (variant) {
    case 'gradient':
      return {
        ...defaultStyle,
        background: mode === 'light' ? grayPalette[mode][100] : BLACK,
      };
    case 'solidDimmed':
      return {
        ...defaultStyle,
        backgroundColor:
          mode === 'light' ? grayPalette[mode][700] : grayPalette[mode][300],
        color: grayPalette[mode][500],
      };
    case 'textPrimary':
    case 'textAssistive':
      return {
        ...defaultStyle,
        backgroundColor: 'transparent',
        border: 'none',
      };
    default:
      return defaultStyle;
  }
};

const getButtonTypographyStyle = (
  variant?: OwnerStateType['variant'],
  size?: 'small' | 'medium' | 'large',
) => {
  if (isOriginVariantKey(variant))
    return {
      fontWeight: 600,
      fontFamily: typography.typography.fontFamily,
    };

  return {
    small: typography.typography.buttonSmall,
    medium: typography.typography.buttonMedium,
    large: typography.typography.buttonLarge,
  }[size || 'medium'];
};

export default getButtonStyle;
