import type {
  ScoreboardData,
  SportType,
  ThemeType,
} from 'types/scoreboardData';

export const convertCommonType = (type: string): ThemeType => {
  const setScoreboards = [
    'badminton',
    'jokgu',
    'sepakTakraw',
    'squash',
    'tableTennis',
    'volleyball',
  ];
  const uniScoreboards = [
    'handball',
    'esports',
    'hockey',
    'iceHockey',
    'bodybuilding',
  ];

  if (setScoreboards.indexOf(type) !== -1) return 'scoreboardHasSet';
  else if (uniScoreboards.indexOf(type) !== -1) return 'universal';
  else return type as ThemeType;
};

export const getConvertedThemeName = (theme: string, type: SportType) => {
  if (theme === 'scoreboardHasSet') {
    return type;
  } else if (theme.includes('universal') || type !== 'universal') {
    return theme.replace('universal', type);
  } else {
    return theme;
  }
};

export const getCurrentTeamScore = (
  scoreboardData: ScoreboardData,
): { awayTeamScore: number; homeTeamScore: number } => {
  const {
    common: { awayTeamScore, homeTeamScore },
    cricket,
    scoreBoard: { type },
    scoreboardHasSet,
    tennis,
  } = scoreboardData;

  const currentTeamScore: {
    awayTeamScore: string | number;
    homeTeamScore: string | number;
  } = {
    awayTeamScore: '0',
    homeTeamScore: '0',
  };

  const convertedType = convertCommonType(type);
  if (convertedType === 'scoreboardHasSet' && scoreboardHasSet) {
    currentTeamScore.awayTeamScore = scoreboardHasSet.awayTeamSetScore;
    currentTeamScore.homeTeamScore = scoreboardHasSet.homeTeamSetScore;
  } else if (convertedType === 'cricket' && cricket) {
    currentTeamScore.awayTeamScore = cricket.target;
    currentTeamScore.homeTeamScore = cricket.total;
  } else if (convertedType === 'tennis' && tennis) {
    currentTeamScore.awayTeamScore = tennis.awaySet;
    currentTeamScore.homeTeamScore = tennis.homeSet;
  } else {
    currentTeamScore.awayTeamScore = awayTeamScore;
    currentTeamScore.homeTeamScore = homeTeamScore;
  }

  return {
    awayTeamScore: Number(currentTeamScore.awayTeamScore),
    homeTeamScore: Number(currentTeamScore.homeTeamScore),
  };
};

export const getConvertedRound = (rootData: ScoreboardData) => {
  let round = 0;
  const {
    common: { quarter },
    scoreBoard: { type },
    baseBall,
    basketBall,
    taekwondo,
    pickleball,
    soccer,
    scoreboardHasSet,
  } = rootData;
  const commonType = convertCommonType(type);

  switch (commonType) {
    case 'baseball':
      round = Number(baseBall?.inning);
      break;
    case 'basketball':
      if (basketBall?.quarter === 'EQ') round = 5;
      else round = Number(basketBall?.quarter.replace(/[^0-9]/g, ''));
      break;
    case 'soccer':
      if (soccer?.matchType === 'REGULAR_TIME' && soccer.isFirstHalf) round = 1;
      if (soccer?.matchType === 'REGULAR_TIME' && !soccer.isFirstHalf)
        round = 2;
      if (soccer?.matchType === 'EXTRA_TIME' && soccer.isFirstHalf) round = 3;
      if (soccer?.matchType === 'EXTRA_TIME' && !soccer.isFirstHalf) round = 4;
      if (soccer?.matchType === 'PENALTIES') round = 5;
      break;
    case 'scoreboardHasSet':
      round =
        Number(scoreboardHasSet?.awayTeamSetScore) +
        Number(scoreboardHasSet?.homeTeamSetScore) +
        1;
      break;
    case 'taekwondo':
      round = taekwondo?.round || 1;
      break;
    case 'pickleball':
      round = pickleball?.set || 1;
      break;
    case 'universal':
      round = Number(quarter);
      break;
  }

  return round;
};

export const getConvertedPlayerCount = (sportType: SportType) => {
  let playerCount = 1;

  switch (sportType) {
    case 'basketball':
      playerCount = 5;
      break;

    case 'volleyball':
    case 'iceHockey':
      playerCount = 6;
      break;

    case 'baseball':
      playerCount = 10;
      break;

    case 'soccer':
    case 'hockey':
    case 'cricket':
    case 'football':
      playerCount = 11;
      break;

    case 'handball':
      playerCount = 14;
      break;

    default:
      break;
  }

  return playerCount;
};
