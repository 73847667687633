import { useEffect } from 'react';

import iOSCommunication from 'utils/iOSCommunication';

const useIOSAPPInitSetting = (darkModeActive: boolean) => {
  useEffect(() => {
    if (iOSCommunication.isIOSAPP) iOSCommunication.createCustomEvent();
  }, []);

  useEffect(() => {
    if (iOSCommunication.isIOSAPP)
      iOSCommunication.sendColorSetting(darkModeActive);
  }, [darkModeActive]);
};

export default useIOSAPPInitSetting;
