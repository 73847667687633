import { useEffect } from 'react';

import { getManager } from 'api/channel';

import { AUTH_SUCCESS, UN_AUTHENTICATE } from 'types/auth';

import useCookie from './useCookie';
import useManagerAuth from './useManagerAuth';

const useManagerAuthentication = () => {
  const { handleInitialLoading, dispatch, updateProfile } = useManagerAuth();
  const { getCookie } = useCookie();

  useEffect(() => {
    const managerAuth = async () => {
      try {
        const managerUid = getCookie('managed_account');

        if (managerUid === null) {
          throw new Error('No managed account');
        }

        handleInitialLoading(true);

        await getManager(managerUid);
        updateProfile('uid', managerUid);
        dispatch({
          type: AUTH_SUCCESS,
        });
      } catch {
        dispatch({
          type: UN_AUTHENTICATE,
        });
      } finally {
        handleInitialLoading(false);
      }
    };
    managerAuth();
  }, [dispatch, getCookie, handleInitialLoading, updateProfile]);
};

export default useManagerAuthentication;
