import { hasCookie, setCookie } from 'cookies-next';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';

import { saveNotificationToken } from 'api/notification';

import { NotificationMessageType } from 'types/notification';

import iOSCommunication from './iOSCommunication';

export const isFCMSupportedBrowser = async () => {
  const isSupportedBrowser = await isSupported();
  const isIOS =
    (typeof navigator !== 'undefined' &&
      /iPhone|iPad|iPod/i.test(navigator.userAgent)) ||
    iOSCommunication.isIOSAPP;

  return !isIOS && isSupportedBrowser;
};

export const requestNotificationPermission = async () => {
  const isAndroid =
    typeof navigator !== 'undefined' && /Android/i.test(navigator.userAgent);

  const isFCMSupported = await isFCMSupportedBrowser();
  if (!isFCMSupported) return;

  if (
    'Notification' in window &&
    Notification.permission === 'default' &&
    (isAndroid ? !hasCookie('notificationPermission') : true)
  ) {
    await Notification.requestPermission().then((permission) => {
      if (permission !== 'default' && isAndroid) {
        setCookie('notificationPermission', permission, {
          maxAge: 60 * 60 * 24 * 14,
        });
      }
    });
  }
};

export const getFcmToken = async () => {
  if (Notification.permission !== 'granted') return null;

  try {
    const messaging = getMessaging();
    const fcmToken = await getToken(messaging, {
      vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
    });
    return fcmToken;
  } catch (error) {
    console.error(`Web push setting error :  ${error}`);
    return null;
  }
};

export const sendFcmTokenToServer = async (token: string, fcmToken: string) => {
  try {
    await saveNotificationToken(token, fcmToken);
  } catch (error) {
    console.error(`Send fcm token error : ${error}`);
  }
};

export const checkNotificationEnabled = (
  messageType: NotificationMessageType,
  notificationEnabled: boolean,
  serviceNotificationEnabled: boolean,
  marketingNotificationEnabled: boolean,
) => {
  // 데스크탑 푸시 알림을 끌 경우 모든 알림 push 차단
  if (!notificationEnabled) {
    return false;
  }

  // 데스크탑 푸시 알림을 켰으나 서비스 알림을 끌 경우, 마케팅 제외 모든 알림 차단.
  if (
    !serviceNotificationEnabled &&
    messageType !== NotificationMessageType.MARKETING
  ) {
    return false;
  }

  // 데스크탑 푸시 알림을 켰으나 마케팅 알림을 끌 경우, 마케팅 알림 차단.
  if (
    !marketingNotificationEnabled &&
    messageType === NotificationMessageType.MARKETING
  ) {
    return false;
  }

  return true;
};
