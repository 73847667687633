import { CircularProgress, Dialog } from '@mui/material';

const CustomCircularProgress = ({
  isLoading = true,
  zIndex = 1300,
}: {
  isLoading?: boolean;
  zIndex?: number;
}) => (
  <Dialog
    open={isLoading}
    sx={{ overflowY: 'hidden', zIndex: zIndex }}
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        overflow: 'hidden',
        boxShadow: 'none',
      },
    }}
  >
    <CircularProgress
      variant="indeterminate"
      sx={{ animationDuration: '800ms' }}
      size={40}
      thickness={4}
    />
  </Dialog>
);

export default CustomCircularProgress;
