// 구글 애널리틱스에서 이벤트를 수집할때 도와주는 함수를 모아놓은 파일입니다.
import { getCookie } from 'cookies-next';

import iOSCommunication from 'utils/iOSCommunication';

const ADD_CUSTOM_DATA_EVENT = 'Add custom data';

const isGTMEnabled = typeof window === 'undefined' || !window.dataLayer;

/**
 * @description Google Tag Manager에서 태그를 만드는데 활용하는 데이터 레이어에 데이터를 추가하는 함수
 *
 * ! 반드시 isProfileLoadProcessComplete가 true일 때만 호출해야 함
 *
 * 구글 태그매니저 스크립트가 isProfileLoadProcessComplete가 true일 때 다운로드되기 때문
 */
export const addEventToDataLayer = ({
  event,
  ...rest
}: {
  event?: string;
  [key: string]: string | number | boolean | undefined;
}) => {
  // 브라우저 환경이 아니거나 dataLayer가 없을 경우 return
  if (!isGTMEnabled) return;

  const isIOSAPP = iOSCommunication.isIOSAPP;
  const isAndroidApp = getCookie('osType') === 'android';

  const getPlatformType = () => {
    switch (true) {
      case isIOSAPP:
        return 'app_ios';
      case isAndroidApp:
        return 'app_android';
      default:
        return 'web';
    }
  };

  window.dataLayer.push({
    event: event || ADD_CUSTOM_DATA_EVENT,
    platformType: getPlatformType(),
    ...rest,
  });
};
