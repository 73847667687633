import { authAxiosWithoutChannelUid } from 'lib/axios';

export const saveNotificationToken = async (
  token: string,
  notificationToken: string,
) =>
  await authAxiosWithoutChannelUid({
    token,
    serverType: 'live',
    version: 'v1',
  })({
    method: 'POST',
    url: '/notification-tokens',
    data: {
      tokenId: notificationToken,
    },
  });
