import { hostAxios } from 'lib/axios';

/**
 * @param cookieName 가져올 쿠키의 이름
 * @returns 쿠키의 값을 반환합니다.
 * @description httpOnly 속성을 사용하는 쿠키를 가져올 때 사용합니다.
 */
export const getCookie = async (cookieName: string) =>
  await hostAxios<{
    [key: string]: string | null;
  }>({
    method: 'GET',
    url: '/api/get_cookie',
    params: { cookieName },
  });
