import type {
  ReactNode} from 'react';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useMediaQuery } from '@mui/material';
import { setCookie } from 'cookies-next';

type DarkModeContextType = ReturnType<typeof useDarkMode>;

const DarkModeContext = createContext<DarkModeContextType | undefined>(
  undefined,
);

// source명
const SYSTEM = 'system' as const;
const CUSTOM = 'custom' as const;

// 테마명
const LIGHT = 'light' as const;
const DARK = 'dark' as const;
const DEFAULT_THEME = LIGHT;

//cookie 이름
const THEME = 'theme' as const;
const THEME_SOURCE = 'themeSource' as const;

/**
 * @param cookieThemeSource - 쿠키에 저장된 테마 소스
 * @param cookieTheme - 쿠키에 저장된 테마
 */
const useDarkMode = ({
  cookieTheme,
  cookieThemeSource,
}: {
  cookieThemeSource: typeof SYSTEM | typeof CUSTOM | null;
  cookieTheme: typeof LIGHT | typeof DARK | null;
}) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const prefersLightMode = useMediaQuery('(prefers-color-scheme: light)');

  const [theme, setTheme] = useState<typeof LIGHT | typeof DARK>(
    cookieTheme || DEFAULT_THEME,
  );
  const [source, setSource] = useState<typeof SYSTEM | typeof CUSTOM>(
    cookieThemeSource || CUSTOM,
  );

  const darkModeActive = useMemo(() => theme === DARK, [theme]);
  const autoModeActive = useMemo(() => source === SYSTEM, [source]);

  const setValues = useCallback(
    ({
      selectedTheme,
      selectedSource,
    }: {
      selectedTheme: typeof LIGHT | typeof DARK;
      selectedSource: typeof SYSTEM | typeof CUSTOM;
    }) => {
      setCookie(THEME, selectedTheme);
      setCookie(THEME_SOURCE, selectedSource);

      setTheme(selectedTheme);
      setSource(selectedSource);
    },
    [],
  );

  const switchToDarkMode = useCallback(() => {
    setValues({ selectedTheme: DARK, selectedSource: CUSTOM });
  }, [setValues]);
  const switchToLightMode = useCallback(() => {
    setValues({ selectedTheme: LIGHT, selectedSource: CUSTOM });
  }, [setValues]);
  const switchToAutoMode = useCallback(() => {
    setValues({
      selectedTheme: prefersDarkMode ? DARK : LIGHT,
      selectedSource: SYSTEM,
    });
    setTheme(prefersDarkMode ? DARK : LIGHT);
  }, [prefersDarkMode, setValues]);

  // 최초 웹사이트 접속 시 쿠키에 저장된 값이 없을 경우 유저 시스템 설정에 따라 테마 설정
  useEffect(() => {
    if (cookieThemeSource === null && cookieTheme === null) {
      switchToAutoMode();
    }
  }, [cookieTheme, cookieThemeSource, switchToAutoMode]);

  // 시스템 테마 설정 변경시 테마 변경
  useEffect(() => {
    if (
      source === SYSTEM &&
      (prefersDarkMode === true || prefersLightMode === true)
    ) {
      switchToAutoMode();
    }
  }, [prefersDarkMode, prefersLightMode, source, switchToAutoMode]);

  return {
    autoModeActive,
    darkModeActive,
    switchToAutoMode,
    switchToDarkMode,
    switchToLightMode,
    theme,
    cookieThemeSource,
  };
};

const DarkModeProvider = ({
  children,
  cookieTheme,
  cookieThemeSource,
}: {
  cookieThemeSource: typeof SYSTEM | typeof CUSTOM | null;
  cookieTheme: typeof LIGHT | typeof DARK | null;
  children: ReactNode;
}) => {
  const darkModeState = useDarkMode({
    cookieTheme,
    cookieThemeSource,
  });

  return (
    <DarkModeContext.Provider value={darkModeState}>
      {children}
    </DarkModeContext.Provider>
  );
};

export { DarkModeProvider, DarkModeContext };
