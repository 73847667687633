import { browserName } from 'react-device-detect';

const isIOSAPP =
  typeof window !== 'undefined' &&
  window?.webkit !== undefined &&
  browserName === 'WebKit';

const CAMERA_PERMISSION_EVENT_NAME = 'IOS_CameraAcceptPermission';
const SUBSCRIPTION_RECEIPT_EVENT_NAME = 'IOS_SubscriptionReceipt';

const postMessage = (message: { method: string; params: object | null }) => {
  window?.webkit?.messageHandlers?.CameraFiStudio?.postMessage(
    JSON.stringify(message),
  );
};

// 앱 실행시 테마에 따른 상단바 색 설정
const sendColorSetting = (darkModeActive: boolean) => {
  let colorInfoParams;

  if (darkModeActive) {
    colorInfoParams = { bottomColor: '0x121212', topColor: '0x121212' };
  } else {
    colorInfoParams = { bottomColor: '0xffffff', topColor: '0xffffff' };
  }

  const colorInfo = {
    method: 'changeSafeAreaColor',
    params: colorInfoParams,
  };

  postMessage(colorInfo);
};

// 접근 권한 요청 코드
const requestPermission = () => {
  const permissionRequest = {
    method: 'privacyPermission',
    params: null,
  };

  postMessage(permissionRequest);
};

// 입앱 결제 요청코드
const requestInAppPurchase = (
  membershipPlan: 'monthly' | 'annual',
  uid: string,
) => {
  const inAppPurchaseRequest = {
    method: 'inAppPurchase',
    params: { subscribe: membershipPlan, uuid: uid },
  };

  postMessage(inAppPurchaseRequest);
};

// 결제 이력 요청 코드
const requestPurchaseHistory = () => {
  const purchaseHistoryRequest = {
    method: 'iosloadPurchaseHistory',
    params: null,
  };

  postMessage(purchaseHistoryRequest);
};

// 구독 취소등에서 앱스토어 실행 요청
const requestOpenAppStore = () => {
  const openAppStoreRequest = {
    method: 'moveToSubscription',
    params: null,
  };

  postMessage(openAppStoreRequest);
};

// 영수증 요청코드, 이걸로 유저의 구독정보 및 타 계정 접속시 구분
const requestRecentReceipt = () => {
  const recentReceiptRequest = {
    method: 'passReceiptToWebView',
    params: null,
  };

  postMessage(recentReceiptRequest);
};

// 로그아웃시, 푸시알림에 필요한 FCM 토큰 삭제 요청
const requestDeleteFCMToken = () => {
  const deleteFCMTokenRequest = {
    method: 'logOut',
    params: null,
  };

  postMessage(deleteFCMTokenRequest);
};

const createCustomEvent = () => {
  window.iosCameraPermissionSuccess = new CustomEvent(
    CAMERA_PERMISSION_EVENT_NAME,
  );
};

const iOSCommunication = {
  CAMERA_PERMISSION_EVENT_NAME,
  SUBSCRIPTION_RECEIPT_EVENT_NAME,
  isIOSAPP,
  sendColorSetting,
  requestPermission,
  requestInAppPurchase,
  requestPurchaseHistory,
  requestOpenAppStore,
  requestRecentReceipt,
  requestDeleteFCMToken,
  createCustomEvent,
};

export default iOSCommunication;
