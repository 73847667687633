const getGaClientId = () => {
  const gaClientId = document?.cookie
    ?.split('; ')
    ?.find((row) => row.startsWith('_ga='))
    ?.split('.')
    ?.slice(2, 4)
    ?.join('.');

  return gaClientId;
};

export default getGaClientId;
