import type { Dispatch } from 'react';

import type { DocumentSnapshot } from 'firebase/firestore/lite';

import type {
  ChannelCoreData,
  ChannelDetailDataType,
  ChannelListCardType,
  ManagedChannelOwnerType,
} from './channel';
import type { FireStoreStudioUserType } from './firestore';

type ActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

//TODO: any 타입 제거
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AuthUser = Record<string, any>;

/**
 * @description
 * 인증 상태를 나타내는 타입
 * @property {boolean} isAnonymousUser - 익명 사용자 여부
 * @property {boolean} isAuthenticatedUser - 인증된 사용자 여부
 * @property {boolean} isProfileLoadedUser - 로그인 사용자 여부
 * @property {boolean} isSubscribedUser - 구독 사용자 여부
 * @property {boolean} isFirebaseAuthSuccess - Firebase 인증 성공 여부
 * @property {boolean} isFirebaseAuthFail - Firebase 인증 실패 여부
 * @property {boolean} isFirebaseAuthenticating - Firebase 인증 중 여부
 * @property {boolean} isUserProfileLoadSuccess - 사용자 프로필 로드 성공 여부
 * @property {boolean} isUserProfileLoadFail - 사용자 프로필 로드 실패 여부
 * @property {boolean} isUserProfileLoading - 사용자 프로필 로드 중 여부
 * @property {boolean} isProfileLoadProcessComplete - 로그인 프로세스 완료 여부 (인증 프로세스, 사용자 프로필 로드 2가지 작업이 모두 성공하거나 하나라도 실패하는 경우) => isAnonymousUser | isProfileLoadedUser
 * @property {boolean} isProfileLoadProcessing - 로그인 프로세스 중 여부
 * @property {boolean} isAuthProcessComplete - 인증 프로세스 완료 여부 (인증 프로세스 성공, 실패 포함)
 */
export type AuthState = {
  isAnonymousUser: boolean;
  isAuthenticatedUser: boolean;
  isProfileLoadedUser: boolean;
  isSubscribedUser: boolean;

  isFirebaseAuthSuccess: boolean;
  isFirebaseAuthFail: boolean;
  isFirebaseAuthenticating: boolean;

  isUserProfileLoadSuccess: boolean;
  isUserProfileLoadFail: boolean;
  isUserProfileLoading: boolean;

  isProfileLoadProcessComplete: boolean;
  isProfileLoadProcessing: boolean;
  isAuthProcessComplete: boolean;

  customToken?: string;
};

interface basicAuthContextType extends AuthState {
  role?: 'owner' | 'manager' | 'guest';
  snapShot?: DocumentSnapshot;
  setChannel: (channel: ChannelDetailDataType) => void;
  initializeProfile: ({
    token,
    uid,
    role,
    manageChannelUid,
  }: initializeProfileType) => Promise<ServerUser | undefined>;
  user: AuthUser;
  channel: Omit<ChannelDetailDataType, 'userId'>;
  updateChannel: (
    type: keyof ChannelDetailDataType,
    newData: ChannelDetailDataType[keyof ChannelDetailDataType],
  ) => void;
  updateSubscriptionState: (
    subscriptionState: FireStoreStudioUserType | null,
  ) => void;
  updateProfile: (
    type:
      | 'userName'
      | 'country'
      | 'language'
      | 'profileUrl'
      | 'nickname'
      | 'uid',
    newData: string,
  ) => void;
  updateAutoPlayEnabled: (isAutoPlay: boolean) => void;
  updateCookieAllowed: (isAllowed: boolean) => void;
  updateNotificationEnabled: (isNotification: boolean) => void;
  updateServiceNotificationEnabled: (isServiceNotification: boolean) => void;
  updateMarketingNotificationEnabled: (
    isMarketingNotification: boolean,
  ) => void;
  updatePaymentAvailability: (
    paymentAvailable: boolean | undefined | null,
  ) => void;
  dispatch: Dispatch<FirebaseActions>;
  onUserSnapshotUpdate: (data: FireStoreStudioUserType) => void;
  resetContext: () => void;
  isUserDataLoading: boolean;
  updateUserDataLoading: (isLoading: boolean) => void;
}
export interface AuthContextType extends basicAuthContextType {
  token: string;
  signIn: () => void;
  updateToken: (token: string) => void;
}

export interface ManagerAuthContextType extends basicAuthContextType {
  updateManagedAccount: (
    managedChannelInfo: ManagedChannelOwnerType,
  ) => Promise<void>;
  clearManagedContext: () => Promise<void>;
  initialLoading: boolean;
  handleInitialLoading: (value: boolean) => void;
}

export interface ActiveAuthContextType
  extends Omit<AuthContextType, 'updateToken'>,
    Omit<ManagerAuthContextType, 'handleInitialLoading' | 'initialLoading'> {
  signOut: () => Promise<void>;
  changeActiveAccount: (value: ManagedChannelOwnerType) => Promise<void>;
  getIsLoginUser: (nickname: string) => boolean;
  role: 'owner' | 'manager' | 'guest';
}

export type ServerUser = {
  uid: string;
  userName: string;
  nickname: string;
  profileUrl: string;
  email: string;
  country?: string;
  language?: string;
  autoPlayEnabled?: boolean;
  cookieAllowed: boolean | null;
  notificationEnabled?: boolean;
  serviceNotificationEnabled?: boolean;
  marketingNotificationEnabled?: boolean;
  paymentAvailability?: boolean;
  role?: 'role-user' | 'role-admin' | 'role-partner';
  camerafiInfo?: {
    id: number;
    status: string;
    lastActivityTime: string;
  };
  camerafiStudioInfo?: {
    id: number;
    status: string;
    lastActivityTime: string;
  };
  camerafiShoppingInfo?: {
    id: number;
    status: string;
    lastActivityTime: string;
  };
  blockedByAdminInfo?: {
    id: number;
    isBlock: boolean;
    blockedReason: string;
    blockedAt: string;
    updatedAt: string;
  };
  createdAt?: string;
  updatedAt?: string;
  studioSubscription?: {
    checkoutId: string;
    isSubscriber: boolean;
    planId: string;
    status: string;
    subscriptionId: string;
    uid: string;
  };
  serviceName?: service[];
  lastSignTime?: string;
  lastActive?: string;
};

export type ServerUserUpdateRequestBody = {
  email?: string;
  phoneNumber?: string;
  username?: string;
  nickname?: string;
  profileUrl?: string;
  callId?: string;
  country?: string;
  language?: string;
  serviceName?: service[];
  autoPlayEnabled?: boolean;
  notificationEnabled?: boolean;
  marketingNotificationEnabled?: boolean;
  serviceNotificationEnabled?: boolean;
  updateService?: {
    description?: string;
    serviceName: service;
  };
  cameraFiLiveInfo?: {
    subscription: boolean;
  };
  cameraFiStudioInfo?: {
    subscription: boolean;
  };
  cameraFiShoppingInfo?: {
    subscription: boolean;
  };
};

type service = 'camerafi-live' | 'camerafi-studio' | 'camerafi-shopping';

export type FollowingPagesType = {
  following: ChannelListCardType[];
  totalCount: number;
  pageCount: number;
  prev: number;
  next: number;
};

export type AdIdType =
  // 규칙 : 페이지_위치_형태_디바이스
  | '검색_검색카운트하단_배너_공통'
  | '경기상세_스코어하단_배너_공통'
  | '메인_첫번째컨텐츠리스트하단_배너_공통'
  | '메인_전체경기상단_배너_공통'
  | '모든_검색배너하단_배너_모바일'
  | '모든_검색배너하단_배너_웹'
  | '모든_경기리스트_배너_공통'
  | '모든_더보기팝업중간_배너_모바일'
  | '모든_생성하기버튼상단_카드_모바일'
  | '모든_우측사이드_카드_웹'
  | '모든_이벤트리스트_배너_공통'
  | '모든_이벤트리스트_카드_공통'
  | '모든_푸터상단_배너_공통'
  | '설정_탭하단_배너_공통'
  | '실시간자막_기록상단_배너_공통'
  | '실시간자막_페이지상단_배너_공통'
  | '이벤트상세_상세내용하단_배너_공통'
  | '이벤트상세_우측리모콘하단_배너_웹'
  | '이벤트상세_페이지상단_배너_공통'
  | '지난자막_페이지상단_배너_공통'
  | '채널_페이지상단_배너_공통'
  | '컨트롤_위젯미리보기하단_배너_공통'
  | '컨트롤_컨트롤러내부_배너_공통'
  | '컨트롤_컨트롤러하단_배너_공통'
  | '컨트롤_테마탭중간_배너_공통';

export type userSearchType = {
  channel: ChannelCoreData;
  email: string;
  nickname: string;
  profileUrl: string;
};

export type initializeProfileType = {
  token: string;
  uid: string;
  role?: 'owner' | 'manager';
  manageChannelUid?: string;
};

export const AUTH_SUCCESS = 'AUTH_SUCCESS' as const;
export const RESET = 'RESET' as const;
export const UN_AUTHENTICATE = 'UN_AUTHENTICATE' as const;
export const USER_PROFILE_LOAD_SUCCESS = 'USER_PROFILE_LOAD_SUCCESS' as const;
export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE' as const;

type AuthActionTypes = {
  [AUTH_SUCCESS]: never;
  [USER_PROFILE_LOAD_SUCCESS]: {
    isSubscribedUser: boolean;
  };
  [USER_PROFILE_UPDATE]: {
    isSubscribedUser: boolean;
  };
  [RESET]: never;
  [UN_AUTHENTICATE]: never;
};

export type FirebaseActions =
  ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>];
