import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { captureMessage } from '@sentry/nextjs';
import axios from 'axios';
import {
  getAuth,
  onIdTokenChanged,
  signInWithCustomToken,
} from 'firebase/auth';
import { useSnackbar } from 'notistack';

import { createSessionCookie, getCustomToken, verifyIdToken } from 'api/auth';
import { getCookie } from 'api/cookie';

import useAuth from 'hooks/useAuth';

import { AUTH_SUCCESS, UN_AUTHENTICATE } from 'types/auth';

import errorMessage from 'utils/errorMessage';

const useUserAuthentication = (existingCustomToken?: string) => {
  const auth = getAuth();
  const { query, replace } = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    updateChannel,
    updateProfile,
    updateToken,
    isAuthenticatedUser,
    token,
  } = useAuth();

  useEffect(() => {
    const authWithSessionCookie = async (existingCustomToken?: string) => {
      try {
        const idTokenFromQuery = query.idToken;
        let customToken = existingCustomToken;

        if (idTokenFromQuery || isAuthenticatedUser) return;
        if (!customToken) {
          const result = await getCustomToken();
          customToken = result.customToken;
        }

        const { user } = await signInWithCustomToken(auth, customToken);

        updateProfile('uid', user.uid);
        updateChannel('profileUrl', user.photoURL);
      } catch (e) {
        dispatch({
          type: UN_AUTHENTICATE,
        });
      }
    };
    authWithSessionCookie(existingCustomToken);
  }, [
    auth,
    dispatch,
    isAuthenticatedUser,
    query.idToken,
    updateChannel,
    updateProfile,
  ]);

  useEffect(() => {
    const createSessionCookieWithQuery = async () => {
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const idTokenFromQuery = urlSearchParams.get('idToken');

        if (Boolean(idTokenFromQuery) === false) return;

        //TODO: 이 아래 if문까지는 불필요한 로직이지 않을까?
        const { __session } = await getCookie('__session');

        if (typeof idTokenFromQuery === 'object' && __session === null) {
          dispatch({
            type: UN_AUTHENTICATE,
          });
          return;
        }

        if (typeof idTokenFromQuery === 'string') {
          await createSessionCookie(idTokenFromQuery, window.location.pathname);
          replace(`${window.location.origin}${window.location.pathname}`);
        }
      } catch (e) {
        dispatch({
          type: UN_AUTHENTICATE,
        });
      }
    };

    createSessionCookieWithQuery();
  }, [dispatch, replace]);

  useEffect(
    () =>
      onIdTokenChanged(auth, async (user) => {
        if (user) {
          try {
            const idToken = await user.getIdToken();

            updateToken(idToken);

            dispatch({
              type: AUTH_SUCCESS,
            });

            await axios({
              method: 'POST',
              url: `${location.origin}/api/firebase/update_session_cookie`,
              data: { idToken },
            });
          } catch (error) {
            dispatch({
              type: UN_AUTHENTICATE,
            });
            enqueueSnackbar(errorMessage(error), {
              variant: 'error',
              persist: true,
            });
          }
          return;
        }
      }),
    [auth, dispatch, enqueueSnackbar, updateToken],
  );

  useEffect(() => {
    const checkIdTokenExpired = async () => {
      try {
        const currentUser = auth.currentUser;

        if (document.visibilityState !== 'visible' || currentUser === null)
          return;

        const { exp } = await verifyIdToken(token);

        const currentTime = Math.floor(Date.now() / 1000);
        const leftTokenExpireTime = exp - currentTime;

        if (leftTokenExpireTime > 0) return;

        await currentUser.getIdToken();
      } catch (e) {
        captureMessage(`checkIdTokenExpired 에러 : ${e}`);
      }
    };

    document.addEventListener('visibilitychange', checkIdTokenExpired);

    return () =>
      document.removeEventListener('visibilitychange', checkIdTokenExpired);
  }, [auth.currentUser, token, updateToken]);
};

export default useUserAuthentication;
