import type { DecodedIdToken } from 'firebase-admin/lib/auth/token-verifier';

import { authAxios, authAxiosWithoutChannelUid, hostAxios } from 'lib/axios';

import type { ServerUser, ServerUserUpdateRequestBody } from 'types/auth';

export const getCountryAndLanguage = async () =>
  await fetch(`https://ip2c.org/s`)
    .then(async (res) => {
      const ip2cData = await res.text();
      return {
        country: ip2cData.split(';')[1] ?? undefined,
        language: navigator.language,
      };
    })
    .catch((error) => {
      console.error('Get country and language error: ', error);
      return { country: undefined, language: navigator.language };
    });

export const getCountry = async () =>
  await fetch(`https://ip2c.org/s`)
    .then(async (res) => {
      const ip2cData = await res.text();
      return ip2cData.split(';')[1] ?? 'KR';
    })
    .catch((error) => {
      console.error('Get country error: ', error);
      return 'KR';
    });

//TODO: 라이브 서버에 저장된 유저 정보를 수정하기 때문에 관리자 정보에 대해서 관리가 안됨.
const updateUser = (
  token: string,
  uid: string,
  body: ServerUserUpdateRequestBody,
) =>
  authAxiosWithoutChannelUid({ token, serverType: 'live', version: 'v1' })({
    method: 'PUT',
    url: `/users/${uid}`,
    data: body,
  });

export const patchUserActivity = (token: string, uid: string) =>
  updateUser(token, uid, {
    serviceName: ['camerafi-studio'],
    updateService: { serviceName: 'camerafi-studio' },
  });

export const getStudioUserData = async (
  token: string,
  id: string,
  channelUid?: string,
): Promise<ServerUser> => {
  if (channelUid)
    return await authAxios({
      token,
      serverType: 'studio',
      version: 'v1',
      channelUid,
    })({
      method: 'GET',
      url: `/users/${channelUid}/studio`,
    });

  return await authAxiosWithoutChannelUid({
    token,
    serverType: 'studio',
    version: 'v1',
  })({
    method: 'GET',
    url: `/users/${id}/studio`,
  });
};

export const updateCountryAndLanguage = (
  token: string,
  uid: string,
  country?: string,
  language?: string,
) => updateUser(token, uid, { country, language });

export const createSessionCookie = async (idToken: string, pathname: string) =>
  await hostAxios<{
    __session: string;
  }>({
    method: 'POST',
    url: '/api/firebase/create_session_cookie',
    data: { idToken, pathname },
  });

export const getCustomToken = async () => {
  return await hostAxios<{ customToken: string }>({
    method: 'GET',
    url: '/api/firebase/get_custom_token',
  });
};

export const verifyIdToken = async (idToken: string) => {
  return await hostAxios<DecodedIdToken>({
    method: 'POST',
    url: '/api/firebase/verify_idToken',
    data: { idToken },
  });
};
