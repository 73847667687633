import { captureMessage } from '@sentry/nextjs';
import type { Database } from 'firebase/database';
import {
  get,
  getDatabase,
  ref,
  remove,
  runTransaction,
  set,
  update,
} from 'firebase/database';

import { addMatchLog, initLog, updateStoreRDB } from 'api/firestore';
import { updateServerMatchDataWithChannelUid } from 'api/scoreboard';

import type {
  FoulType,
  MatchPlayer,
  PenaltiesType,
  PlayerChangeLogType,
  PlayerFoulLogType,
  PlayerOutLogType,
  PlayerScoreLogType,
  PopupMatchData,
  RoundEndLogType,
  RoundStartLogType,
  ServerMatchDetailDataType,
  StatusType,
  TeamFoulLogType,
  TeamScoreLogType,
  TeamSetScoreLogType,
  TimeOutLogType,
  iceHockeyFoulType,
} from 'types/matchData';
import type {
  BaseBall,
  Common,
  MatchData,
  Scoreboard,
  ScoreboardData,
  SportType,
  TeamType,
  Time,
  WidgetImgURL,
  scoreboardHasSet,
} from 'types/scoreboardData';

import { convertCommonType, getConvertedRound } from 'utils/getConvertedValue';
import { initialScoreboardData } from 'utils/getInitialScoreboardData';

import {
  updateScoreboardHasSetData,
  updateSetScoreCustomPoint,
} from './scoreboardHasSet';

let db: Database | null = null;
// * GV : Global Variable
let GV_startTime = 0;
let GV_pauseTime = Date.now();
let GV_pauseMatchTime = 0;
let GV_stackPauseTime = 0;

export const getRef = (address: string) => {
  if (db) return ref(db, address);
  else {
    db = getDatabase();
    return ref(db, address);
  }
};

export const getRDBData = async (
  matchId: string,
): Promise<ScoreboardData | undefined> => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  return (await get(rootRef)).val();
};

export const getScoreBoardData = async (matchId: string) => {
  const scoreboardRDBRef = getRef(`scoreboard/${matchId}`);
  return (await get(scoreboardRDBRef)).val();
};

export const deleteRDB = async (matchId: string) => {
  const matchRDBRef = getRef(`scoreboard/${matchId}`);
  remove(matchRDBRef);
};

export const saveRDBBeforeDelete = async (matchId: string) => {
  const matchRDBRef = getRef(`scoreboard/${matchId}`);
  const matchRDBData = (await get(matchRDBRef)).val();

  if (!matchRDBData) return;

  delete matchRDBData.matchData;
  try {
    await updateStoreRDB(matchId, matchRDBData);
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(
        `${error.message} Occurred from saveRDBBeforeDelete function.`,
      );
    } else {
      throw new Error(`${error} Occurred from saveRDBBeforeDelete function.`);
    }
  }

  remove(matchRDBRef);
};

export const getInitData = async (
  matchId: string,
  widgetImgUrlList: WidgetImgURL[],
  isSubscribedUser: boolean,
  matchData: {
    RDB?: ScoreboardData;
    awayPlayers: MatchPlayer[];
    awayTeamAvatar: string | null;
    awayTeamName: string;
    homePlayers: MatchPlayer[];
    homeTeamAvatar: string | null;
    homeTeamName: string;
    title: string;
    sportType: SportType;
    status: StatusType;
    leagueArea: string | null;
    detailAddress: string | null;
    matchNumber: number | null;
    matchType: 'bestOf3' | 'default' | null;
    matchDate: string;
    matchStartTime: string;
  },
): Promise<ScoreboardData> => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  const dataFromRDB = (await get(rootRef)).val();
  const NOW = Date.now();

  const {
    RDB: RDBFromStore,
    awayPlayers,
    awayTeamAvatar,
    awayTeamName,
    homePlayers,
    homeTeamAvatar,
    homeTeamName,
    title,
    sportType,
    status,
    leagueArea,
    detailAddress,
    matchNumber,
    matchType,
    matchDate,
    matchStartTime,
  } = matchData;

  initLog(matchId, sportType);
  if (dataFromRDB) {
    const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
      if (rootData) {
        rootData.matchData.homePlayers =
          homePlayers.length !== 0 ? homePlayers : '';
        rootData.matchData.awayPlayers =
          awayPlayers.length !== 0 ? awayPlayers : '';
        rootData.matchData.matchStatus = status;
        rootData.matchData.matchDate = matchDate ?? '';
        rootData.matchData.matchStartTime = matchStartTime ?? '';
        rootData.createdAt = NOW;
        rootData.common.isSubscribed = isSubscribedUser;
      }
      return rootData;
    });

    GV_startTime = dataFromRDB.time.startTime;
    GV_pauseMatchTime = dataFromRDB.time.pauseMatchTime;
    return result.snapshot.val();
  }

  let initData = initialScoreboardData;

  if (!RDBFromStore || Object.keys(RDBFromStore).length === 0) {
    const convertedCommonType = convertCommonType(sportType);

    const scoreboardWidgetImg = widgetImgUrlList.find(
      (widgetImg) => widgetImg.theme === convertedCommonType,
    );
    const matchWidgetImg = widgetImgUrlList.find(
      (widgetImg) => widgetImg.theme === 'theme',
    );

    initData.scoreBoard.type = sportType;
    initData.scoreBoard.theme = convertedCommonType;
    initData.overlay.overlayList[0].themeList[0].theme = convertedCommonType;
    initData.overlay.overlayList[0].selectedThemeImgURL =
      scoreboardWidgetImg?.url ?? '';
    initData.overlay.overlayList[1].selectedThemeImgURL =
      matchWidgetImg?.url ?? '';

    initData.matchData.homePlayers =
      homePlayers.length !== 0 ? homePlayers : '';
    initData.matchData.awayPlayers =
      awayPlayers.length !== 0 ? awayPlayers : '';
    initData.common.matchName = title;
    initData.common.homeTeamName = homeTeamName;
    initData.common.awayTeamName = awayTeamName;
    initData.common.homeTeamAvatar = homeTeamAvatar ?? '';
    initData.common.awayTeamAvatar = awayTeamAvatar ?? '';
    initData.common.leagueArea = leagueArea ?? '';
    initData.common.detailAddress = detailAddress ?? '';
    initData.common.matchNumber = matchNumber;
    initData.common.isSubscribed = isSubscribedUser;
    initData.time.statusOfTime = 'READY';
    initData.matchData.matchStatus = status;
    initData.matchData.matchDate = matchDate ?? '';
    initData.matchData.matchStartTime = matchStartTime ?? '';
    initData.createdAt = NOW;

    if (sportType === 'taekwondo' && initData.taekwondo) {
      initData.taekwondo.matchType = matchType ? matchType : 'default';
    }

    const defaultData = {
      common: initData.common,
      overlay: initData.overlay,
      matchData: initData.matchData,
      scoreBoard: initData.scoreBoard,
      time: initData.time,
      createdAt: initData.createdAt,
    };
    switch (sportType) {
      case 'baseball':
        initData = { ...defaultData, baseBall: initData.baseBall };
        break;
      case 'basketball':
        initData = {
          ...defaultData,
          basketBall: initData.basketBall,
          time: { ...defaultData.time, setTime: 600 },
        };
        break;
      case 'soccer': {
        const getTeamPenaltiesResult = (teamType: TeamType) => {
          const targetPlayers = teamType === 'home' ? homePlayers : awayPlayers;
          return targetPlayers.slice(0, 5).map(({ id, name, number }) => ({
            id,
            name,
            number,
            penaltiesType: 'undo' as PenaltiesType,
          }));
        };

        const makeDummyPlayer = (idx: number) => ({
          id: `dummy${idx + 1}`,
          name: '',
          number: idx + 1,
          penaltiesType: 'undo' as PenaltiesType,
        });

        const homePenaltiesResult = getTeamPenaltiesResult('home');
        const awayPenaltiesResult = getTeamPenaltiesResult('away');

        for (let i = 0; i < 5; i++) {
          if (!homePenaltiesResult[i])
            homePenaltiesResult[i] = makeDummyPlayer(i);

          if (!awayPenaltiesResult[i])
            awayPenaltiesResult[i] = makeDummyPlayer(i);
        }

        initData = {
          ...defaultData,
          soccer: initData.soccer
            ? {
                ...initData.soccer,
                homePenaltiesResult,
                awayPenaltiesResult,
              }
            : initData.soccer,
          time: { ...defaultData.time, isCountUp: true, setTime: 86400 },
        };
        break;
      }
      case 'billiard':
        initData = { ...defaultData, billiard: initData.billiard };
        break;
      case 'cricket':
        initData = { ...defaultData, cricket: initData.cricket };
        break;
      case 'football':
        initData = { ...defaultData, footBall: initData.footBall };
        break;
      case 'futsal':
        initData = {
          ...defaultData,
          futsal: initData.futsal,
        };
        break;
      case 'golf':
        initData = { ...defaultData, golf: initData.golf };
        break;
      case 'badminton':
      case 'jokgu':
      case 'sepakTakraw':
      case 'squash':
      case 'tableTennis':
      case 'volleyball':
        initData = {
          ...defaultData,
          scoreboardHasSet: initData.scoreboardHasSet,
        };
        break;
      case 'pickleball':
        initData = { ...defaultData, pickleball: initData.pickleball };
        break;
      case 'rugby':
        initData = {
          ...defaultData,
          rugby: initData.rugby,
          time: {
            ...defaultData.time,
            isCountUp: true,
          },
          matchData: {
            ...defaultData.matchData,
            matchDate: matchDate,
            matchStartTime: matchStartTime,
          },
        };
        break;
      case 'tennis':
        initData = { ...defaultData, tennis: initData.tennis };
        break;
      case 'taekwondo':
        initData = {
          ...defaultData,
          taekwondo: initData.taekwondo,
          time: { ...defaultData.time, setTime: 120 },
        };
        break;
      default:
        initData = defaultData;
        break;
    }
  } else {
    initData = {
      ...RDBFromStore,
      matchData: {
        homePlayers: homePlayers.length !== 0 ? homePlayers : '',
        awayPlayers: awayPlayers.length !== 0 ? awayPlayers : '',
        matchStatus: status,
        matchDate: matchDate,
        matchStartTime: matchStartTime,
      },
      common: {
        ...RDBFromStore.common,
        awayTeamAvatar: awayTeamAvatar ?? '',
        homeTeamAvatar: homeTeamAvatar ?? '',
        matchName: title,
        leagueArea: leagueArea ?? '',
        detailAddress: detailAddress ?? '',
        homeTeamName,
        awayTeamName,
        isSubscribed: isSubscribedUser,
      },
      createdAt: NOW,
    };
    GV_startTime = RDBFromStore.time.startTime;
    GV_pauseMatchTime = RDBFromStore.time.pauseMatchTime;
  }
  await set(rootRef, initData);

  return initData;
};

/**
 * @description RDB가 있는 경우 서버 데이터를 현재 RDB 값을 바탕으로 업데이트 후 업데이트 된 경기 정보 반환
 * RDB가 없는 경우 null 반환
 * @param token 서버 업데이트용 토큰
 * @param matchId RDB 조회 및 업데이트할 경기 아이디
 */
export const updateRDBToServer = async (
  token: string,
  matchId: string,
  manageChannelUid?: string,
): Promise<ServerMatchDetailDataType | null> => {
  const matchRDBRef = getRef(`scoreboard/${matchId}`);

  const matchRDBData = (await get(matchRDBRef)).val();
  if (!matchRDBData) return null;

  const awayPlayers = matchRDBData.matchData.awayPlayers || [];
  const homePlayers = matchRDBData.matchData.homePlayers || [];

  const { awayTeamScore, homeTeamScore } = getFinalScore(matchRDBData);

  const updatedServerMatchData = await updateServerMatchDataWithChannelUid(
    token,
    matchId,
    {
      updateMatchInfo: {
        awayTeamImg: matchRDBData.common.awayTeamAvatar || null,
        awayTeamName: matchRDBData.common.awayTeamName,
        awayTeamScore: isNaN(Number(awayTeamScore))
          ? null
          : Number(awayTeamScore),
        homeTeamImg: matchRDBData.common.homeTeamAvatar || null,
        homeTeamName: matchRDBData.common.homeTeamName,
        homeTeamScore: isNaN(Number(homeTeamScore))
          ? null
          : Number(homeTeamScore),
        title: matchRDBData.common.matchName,
        date: matchRDBData.matchData.matchDate,
        startTime: matchRDBData.matchData.matchStartTime,
      },
      updatePlayerInfo: [...awayPlayers, ...homePlayers],
    },
    manageChannelUid,
  );
  return updatedServerMatchData.matchInfo;
};

export const updateStoreToRDB = async (
  matchId: string,
  updatedData: PopupMatchData,
) => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  await runTransaction(rootRef, (rootData: ScoreboardData) => {
    if (rootData) {
      rootData.common.awayTeamAvatar = updatedData.awayTeamImg ?? '';
      rootData.common.awayTeamName = updatedData.awayTeamName ?? '';
      rootData.common.homeTeamAvatar = updatedData.homeTeamImg ?? '';
      rootData.common.homeTeamName = updatedData.homeTeamName ?? '';
      rootData.common.matchName = updatedData.title ?? '';
      rootData.common.leagueArea = updatedData.leagueArea ?? '';
      rootData.common.detailAddress = updatedData.detailAddress ?? '';
      rootData.common.matchNumber = updatedData.matchNumber;
      rootData.matchData.awayPlayers =
        updatedData.awayPlayers.length === 0 ? '' : updatedData.awayPlayers;
      rootData.matchData.homePlayers =
        updatedData.homePlayers.length === 0 ? '' : updatedData.homePlayers;
      rootData.matchData.matchStatus = updatedData.status;
      rootData.matchData.matchDate = updatedData.date;
      rootData.matchData.matchStartTime = updatedData.startTime;
    }
    return rootData;
  });
};

export const updateTimerReset = async (
  matchId: string,
  matchType?: 'REGULAR_TIME' | 'EXTRA_TIME',
) => {
  const timeRef = getRef(`scoreboard/${matchId}/time`);
  GV_startTime = 0;
  GV_pauseTime = 0;
  GV_pauseMatchTime = 0;
  GV_stackPauseTime = 0;
  await runTransaction(timeRef, (timeData: Time) => {
    if (timeData) {
      timeData.pauseMatchTime = 0;
      timeData.startTime = GV_startTime;
      timeData.statusOfTime = 'READY';

      if (matchType === 'EXTRA_TIME') {
        timeData.statusOfExtraTime = 'READY';
      }
    }
    return timeData;
  });
};

export const updateTimerFirstStart = async (
  matchId: string,
  matchType?: 'REGULAR_TIME' | 'EXTRA_TIME',
) => {
  const timeRef = getRef(`scoreboard/${matchId}/time`);
  GV_startTime = Date.now();
  await runTransaction(timeRef, (timeData: Time) => {
    if (timeData) {
      timeData.startTime = GV_startTime;
      timeData.statusOfTime = 'RUNNING';
      timeData.statusOfExtraTime = 'READY';

      if (
        matchType === 'EXTRA_TIME' &&
        timeData.statusOfExtraTime === 'READY'
      ) {
        timeData.statusOfExtraTime = 'RUNNING';
      }
    }
    return timeData;
  });
};

export const updateTimerPause = async (
  matchId: string,
  matchType?: 'REGULAR_TIME' | 'EXTRA_TIME',
) => {
  const timeRef = getRef(`scoreboard/${matchId}/time`);
  if (GV_startTime === 0) return;
  GV_pauseTime = Date.now();
  await runTransaction(timeRef, (timeData: Time) => {
    if (timeData) {
      timeData.statusOfTime = 'PAUSE';

      if (matchType === 'EXTRA_TIME') {
        timeData.statusOfExtraTime = 'PAUSE';
      }
    }
    return timeData;
  });
};

export const updateTimerRestart = async (
  matchId: string,
  matchType?: 'REGULAR_TIME' | 'EXTRA_TIME',
) => {
  const timeRef = getRef(`scoreboard/${matchId}/time`);
  if (GV_startTime === 0) return;
  GV_pauseTime = Date.now() - GV_pauseTime;
  GV_stackPauseTime =
    GV_stackPauseTime + Math.round(GV_pauseTime / 1000) * 1000;
  await runTransaction(timeRef, (timeData: Time) => {
    if (timeData) {
      timeData.startTime = GV_startTime + GV_stackPauseTime;
      timeData.statusOfTime = 'RUNNING';

      if (
        matchType === 'EXTRA_TIME' &&
        timeData.statusOfExtraTime === 'READY'
      ) {
        timeData.statusOfExtraTime = 'RUNNING';
      }
    }
    return timeData;
  });
};

export const updatePauseTime = async (matchId: string, pauseTime: number) => {
  let logTime = (Date.now() - GV_startTime - GV_stackPauseTime) / 1000;
  logTime = logTime % 1 < 0.1 ? Math.floor(logTime - 1) : Math.floor(logTime);

  if (pauseTime !== logTime) {
    GV_startTime = GV_startTime + (logTime - pauseTime) * 1000;
  }
  GV_pauseMatchTime = pauseTime;

  const timeRef = getRef(`scoreboard/${matchId}/time`);
  await runTransaction(timeRef, (timeData: Time) => {
    if (timeData) {
      timeData.pauseMatchTime = GV_pauseMatchTime;
    }
    return timeData;
  });
};

export const updateHomeTeamName = async (
  matchId: string,
  homeTeamName: string,
) => {
  const commonRef = getRef(`scoreboard/${matchId}/common`);
  await update(commonRef, { homeTeamName });
};

export const updateAwayTeamName = async (
  matchId: string,
  awayTeamName: string,
) => {
  const commonRef = getRef(`scoreboard/${matchId}/common`);
  await update(commonRef, { awayTeamName });
};

export const updateMatchName = async (matchId: string, matchName: string) => {
  const commonRef = getRef(`scoreboard/${matchId}/common`);
  await update(commonRef, { matchName });
};

export const updateMatchStatus = async (
  matchId: string,
  matchStatus: StatusType,
) => {
  const matchDataRef = getRef(`scoreboard/${matchId}/matchData`);
  await update(matchDataRef, { matchStatus });
};

export const updateHomeTeamAvatar = (
  matchId: string,
  homeTeamAvatar: string,
) => {
  const commonRef = getRef(`scoreboard/${matchId}/common`);
  update(commonRef, { homeTeamAvatar });
};

export const updateAwayTeamAvatar = (
  matchId: string,
  awayTeamAvatar: string,
) => {
  const commonRef = getRef(`scoreboard/${matchId}/common`);
  update(commonRef, { awayTeamAvatar });
};

export const updateTheme = async (matchId: string, theme: string) => {
  const scoreBoardRef = getRef(`scoreboard/${matchId}/scoreBoard`);
  await update(scoreBoardRef, { theme });
};

export const updateImageShowHide = async (
  matchId: string,
  isImageShow: boolean,
) => {
  const commonRef = getRef(`scoreboard/${matchId}/common`);
  await update(commonRef, { isImageShow });
};

export const updateTimerShowHide = async (
  matchId: string,
  isTimerShow: boolean,
) => {
  const timeRef = getRef(`scoreboard/${matchId}/time`);
  await update(timeRef, { isTimerShow });
};

export const updateCountMode = async (matchId: string, isCountUp: boolean) => {
  const timeRef = getRef(`scoreboard/${matchId}/time`);
  await runTransaction(timeRef, (timeData: Time) => {
    if (timeData) {
      if (isCountUp) {
        timeData.stopWatchStartTime = timeData.setTime;
        timeData.setTime = 60 * 60 * 24;
      } else {
        timeData.setTime = timeData.stopWatchStartTime;
        timeData.stopWatchStartTime = 0;
      }
    }
    timeData.isCountUp = isCountUp;
    return timeData;
  });
};

export const updateTimerStartTime = async (
  matchId: string,
  setTime: number,
) => {
  const timeRef = getRef(`scoreboard/${matchId}/time`);
  await runTransaction(timeRef, (timeData: Time) => {
    if (timeData) {
      if (timeData.isCountUp) {
        timeData.stopWatchStartTime = setTime;
      } else {
        timeData.setTime = setTime;
      }
    }
    return timeData;
  });
};

export const updateTimeDuration = async (
  matchId: string,
  timeDuration: number,
  type: string,
) => {
  const soccerRef = getRef(`scoreboard/${matchId}/soccer`);
  await update(soccerRef, { [`${type}TimeDuration`]: timeDuration });
};

export const updateControlTheme = async (
  matchId: string,
  controlTheme: string,
) => {
  const scoreBoardRef = getRef(`scoreboard/${matchId}/scoreBoard`);
  try {
    await runTransaction(scoreBoardRef, (scoreboardData: Scoreboard) => {
      scoreboardData.controlTheme = controlTheme;
      return scoreboardData;
    });
  } catch (error) {
    captureMessage(`updateControlTheme 에러: ${error}`);
    throw error;
  }
};

export const updateTeamLineUpSetting = async (
  matchId: string,
  isHomeTeamLineUp: boolean,
) => {
  const commonRef = getRef(`scoreboard/${matchId}/common`);
  await update(commonRef, { isHomeTeamLineUp });
};

export const updateScoreCustomPoint = async (
  matchId: string,
  teamType: TeamType,
  point: number,
) => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  let diff = 0;
  const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
    if (rootData) {
      const numTeamScore = Number(rootData.common[`${teamType}TeamScore`]);
      let updatedScore = numTeamScore + point;
      updatedScore =
        updatedScore > 999 ? 999 : updatedScore < 0 ? 0 : updatedScore;
      diff = updatedScore - numTeamScore;
      rootData.common[`${teamType}TeamScore`] = `${updatedScore}`.padStart(
        2,
        '0',
      );
    }
    return rootData;
  });

  const rootData = result.snapshot.val() as ScoreboardData;
  if (diff !== 0) {
    await makeTeamScoreLog(matchId, rootData, diff, teamType);
  }

  const {
    common,
    scoreboardHasSet,
    scoreBoard: { controlTheme },
  } = rootData;

  if (scoreboardHasSet && controlTheme === '3' && point === 1)
    updateServeAndSet(matchId, teamType, common, scoreboardHasSet);
};

const updateServeAndSet = async (
  matchId: string,
  teamType: TeamType,
  common: Common,
  scoreboardHasSet: scoreboardHasSet,
) => {
  const { homeTeamScore, awayTeamScore } = common;
  const { serve, endPoint, serveSwitchPoint, isDeuce } = scoreboardHasSet;
  const convertedHomeTeamScore = Number(homeTeamScore);
  const convertedAwayTeamScore = Number(awayTeamScore);
  const scoreDifference = Math.abs(
    convertedHomeTeamScore - convertedAwayTeamScore,
  );

  // home or away 중 한 팀의 점수가 endPoint에 도달했을 때
  // deuce가 OFF이면, update Set
  // deuce가 ON이면, 양 팀의 점수차가 2점 이상일 경우 update Set
  if (
    convertedHomeTeamScore >= endPoint ||
    convertedAwayTeamScore >= endPoint
  ) {
    if (!isDeuce || (isDeuce && scoreDifference >= 2)) {
      const winnerTeam =
        convertedHomeTeamScore > convertedAwayTeamScore ? 'home' : 'away';
      await updateScoreboardHasSetData(matchId, 'serve', winnerTeam);
      await updateSetScoreCustomPoint(matchId, winnerTeam, 1);
      return;
    }
  }

  // 서브 교환 점수의 value가 "scorer"이면, 점수가 오를 때마다 득점한 팀이 서브권을 가짐
  // else, 서브 교환 점수만큼의 점수가 날 때마다 서브 교환
  // EX) 서브 교환 점수가 3이면, 양 팀 합해서 3점의 점수가 오르면 서브 교환
  if (serveSwitchPoint === 'scorer') {
    await updateScoreboardHasSetData(matchId, 'serve', teamType);
  } else {
    const scoreSum = convertedHomeTeamScore + convertedAwayTeamScore;
    if (scoreSum % Number(serveSwitchPoint) === 0) {
      const targetTeam = serve === 'home' ? 'away' : 'home';
      await updateScoreboardHasSetData(matchId, 'serve', targetTeam);
    }
  }
};

export const resetScore = async (matchId: string, teamType: TeamType) => {
  const commonRef = getRef(`scoreboard/${matchId}/common`);
  await update(
    commonRef,
    teamType === 'home' ? { homeTeamScore: '00' } : { awayTeamScore: '00' },
  );
};

export const updateTeamScore = async (
  matchId: string,
  key: string,
  score: string,
  teamType: TeamType,
) => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  let diff = 0;
  const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
    if (rootData) {
      diff = Number(score) - Number(rootData.common[key]);
      rootData.common[key] = score;
    }
    return rootData;
  });

  if (diff !== 0) {
    const rootData = result.snapshot.val() as ScoreboardData;
    await makeTeamScoreLog(matchId, rootData, diff, teamType);
  }
};

export const updateTeamColor = async (
  matchId: string,
  key: string,
  color: string,
) => {
  const commonRef = getRef(`scoreboard/${matchId}/common`);
  await runTransaction(commonRef, (commonData) => {
    if (commonData) {
      commonData[key] = color;
    }
    return commonData;
  });
};

export const updateTeamFontSize = async (
  matchId: string,
  team: TeamType,
  diff: number,
) => {
  const commonRef = getRef(`scoreboard/${matchId}/common`);
  await runTransaction(commonRef, (commonData) => {
    if (commonData) {
      const updatedFontSize =
        commonData[`${team}TeamFontSize`] + diff > 0
          ? commonData[`${team}TeamFontSize`] + diff
          : 1;
      commonData[`${team}TeamFontSize`] = updatedFontSize;
    }
    return commonData;
  });
};

export const updateMatchNameFontSize = async (
  matchId: string,
  diff: number,
) => {
  const commonRef = getRef(`scoreboard/${matchId}/common`);
  await runTransaction(commonRef, (commonData) => {
    if (typeof commonData.matchNameFontSize === 'number') {
      const updatedFontSize =
        commonData.matchNameFontSize + diff > 0
          ? commonData.matchNameFontSize + diff
          : 0.5;
      commonData.matchNameFontSize = updatedFontSize;
    } else commonData.matchNameFontSize = 3;
    return commonData;
  });
};

export const updatePlayerNameFontSize = async (
  matchId: string,
  diff: number,
) => {
  const commonRef = getRef(`scoreboard/${matchId}/common`);
  await runTransaction(commonRef, (commonData) => {
    if (typeof commonData.playerFontSize === 'number') {
      const updatedFontSize =
        commonData.playerFontSize + diff >= 0.2
          ? Number((commonData.playerFontSize + diff).toFixed(1))
          : 0.2;
      commonData.playerFontSize = updatedFontSize;
    } else commonData.playerFontSize = 2;
    return commonData;
  });
};

export const updateCustomCss = async (matchId: string, customCss: string) => {
  const commonRef = getRef(`scoreboard/${matchId}/common`);
  await update(commonRef, { customCss });
};

export const updatePlayerTeamScore = async (
  matchId: string,
  teamType: TeamType,
  player: MatchPlayer,
  point: number,
) => {
  if (point === 0) return;
  const rootRef = getRef(`scoreboard/${matchId}`);
  const { goalCount, id } = player;
  const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
    if (rootData) {
      const teamPlayers = rootData.matchData[`${teamType}Players`];
      if (teamPlayers === '') return rootData;

      rootData.common[`${teamType}TeamScore`] = `${
        Number(rootData.common[`${teamType}TeamScore`]) + point
      }`.padStart(2, '0');

      rootData.matchData[`${teamType}Players`] = teamPlayers.map(
        (player: MatchPlayer) => {
          if (id === player.id) player.goalCount = goalCount;
          return player;
        },
      );
    }

    return rootData;
  });

  const rootData = result.snapshot.val() as ScoreboardData;
  if (point > 0) {
    await makePlayerScoreLog(matchId, rootData, player, point, teamType);
  } else {
    await makeCancelPlayerScoreLog(matchId, rootData, player, point, teamType);
  }

  const {
    common,
    scoreboardHasSet,
    scoreBoard: { controlTheme },
  } = rootData;

  if (scoreboardHasSet && controlTheme === '3' && point === 1)
    updateServeAndSet(matchId, teamType, common, scoreboardHasSet);
};

export const updatePlayerFoul = async (
  matchId: string,
  player: MatchPlayer,
  teamType: TeamType,
  foulType?: FoulType | iceHockeyFoulType,
  updatedFoulList?: (FoulType | iceHockeyFoulType)[],
) => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  const { warningCount, id } = player;

  const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
    if (rootData) {
      const teamPlayers = rootData.matchData[`${teamType}Players`];
      if (teamPlayers === '') return rootData;
      rootData.matchData[`${teamType}Players`] = teamPlayers.map(
        (player: MatchPlayer) => {
          if (id === player.id) {
            player.warningCount = warningCount;
            if (updatedFoulList) player.fouls = updatedFoulList;
          }
          return player;
        },
      );
    }
    return rootData;
  });

  const rootData = result.snapshot.val() as ScoreboardData;
  await makePlayerFoulLog(matchId, rootData, player, teamType, foulType);
};

export const cancelPlayerFoul = async (
  matchId: string,
  player: MatchPlayer,
  teamType: TeamType,
  foulType: FoulType | iceHockeyFoulType,
  updatedFoulList: (FoulType | iceHockeyFoulType)[],
) => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  const { warningCount, id } = player;
  const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
    if (rootData) {
      const teamPlayers = rootData.matchData[`${teamType}Players`];
      if (teamPlayers === '') return rootData;

      rootData.matchData[`${teamType}Players`] = teamPlayers.map(
        (player: MatchPlayer) => {
          if (id === player.id) {
            player.warningCount = warningCount;
            player.fouls = updatedFoulList;
          }
          return player;
        },
      );
    }
    return rootData;
  });

  const rootData = result.snapshot.val() as ScoreboardData;
  await makeCancelPlayerFoulLog(matchId, rootData, player, teamType, foulType);
};

export const updatePlayerChange = async (
  matchId: string,
  teamType: TeamType,
  outPlayerInfo: string,
  inPlayerInfo: string,
  updatedPlayerList: MatchPlayer[],
) => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
    if (rootData) {
      teamType === 'home'
        ? (rootData.matchData.homePlayers = updatedPlayerList)
        : (rootData.matchData.awayPlayers = updatedPlayerList);
    }
    return rootData;
  });

  const rootData = result.snapshot.val() as ScoreboardData;
  await makePlayerChangeLog(
    matchId,
    rootData,
    inPlayerInfo,
    outPlayerInfo,
    teamType,
  );
};

const getReorderedPlayer = (updatedPlayers: MatchPlayer[]): MatchPlayer[] => {
  let mainPlayerIdx = 0;
  let subPlayerIdx = 0;

  return updatedPlayers.map((player) => {
    if (player.type === 'main') {
      player.index = mainPlayerIdx++;
    } else {
      player.index = subPlayerIdx++;
    }
    return player;
  });
};

export const updatePlayers = async (
  matchId: string,
  teamType: TeamType,
  updatedPlayers: MatchPlayer[],
) => {
  const matchDataRef = getRef(`scoreboard/${matchId}/matchData`);
  const reorderedPlayers = getReorderedPlayer(updatedPlayers);

  await runTransaction(matchDataRef, (matchData: MatchData) => {
    if (matchData) {
      teamType === 'home'
        ? (matchData.homePlayers = reorderedPlayers)
        : (matchData.awayPlayers = reorderedPlayers);
    }
    return matchData;
  });
};

export const clearHitterId = async (matchId: string, teamType: TeamType) => {
  const baseBallRef = getRef(`scoreboard/${matchId}/baseBall`);
  await runTransaction(baseBallRef, (baseBallData: BaseBall) => {
    if (baseBallData) {
      teamType === 'home'
        ? (baseBallData.homeCurrentHitterId = -1)
        : (baseBallData.awayCurrentHitterId = -1);
    }
    return baseBallData;
  });
};

// export const updateMainPlayerList = async (
//   matchId: string,
//   teamType: TeamType,
//   updatedPlayerList: MatchPlayer[],
// ) => {
//   const playersRef = getRef(
//     `scoreboard/${matchId}/matchData/${teamType}Players`,
//   );
//   await runTransaction(playersRef, (playerListData: MatchPlayer[]) => {
//     const newPlayerList = playerListData.map((player, index) => {
//       if (player.type === 'main') return updatedPlayerList[index];
//       return player;
//     });
//     return newPlayerList;
//   });
// };

export const updateCopiedData = async (
  matchId: string,
  updatedCommonData: object,
) => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  await update(rootRef, updatedCommonData);
};

export const playerOut = async (
  matchId: string,
  outPlayer: MatchPlayer,
  teamType: TeamType,
) => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
    if (rootData) {
      const teamPlayers = rootData.matchData[`${teamType}Players`];
      if (teamPlayers === '') return rootData;

      rootData.matchData[`${teamType}Players`] = teamPlayers.map(
        (player: MatchPlayer) => {
          if (outPlayer.id === player.id) return outPlayer;
          return player;
        },
      );
    }
    return rootData;
  });

  const rootData = result.snapshot.val() as ScoreboardData;
  await makePlayerOutLog(matchId, outPlayer, rootData, teamType);
};

export const cancelPlayerOut = async (
  matchId: string,
  targetPlayer: MatchPlayer,
  teamType: TeamType,
) => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
    if (rootData) {
      const teamPlayers = rootData.matchData[`${teamType}Players`];
      if (teamPlayers === '') return rootData;

      rootData.matchData[`${teamType}Players`] = teamPlayers.map(
        (player: MatchPlayer) => {
          if (targetPlayer.id === player.id) return targetPlayer;
          return player;
        },
      );
    }
    return rootData;
  });

  const rootData = result.snapshot.val() as ScoreboardData;
  await makeCancelPlayerOutLog(matchId, targetPlayer, rootData, teamType);
};

export const makeTeamScoreLog = async (
  matchId: string,
  rootData: ScoreboardData,
  diff: number,
  teamType: TeamType,
) => {
  const { common, time } = rootData;
  const logObj: TeamScoreLogType = {
    type: 'TEAM_SCORE',
    matchTime: getMatchTime(time),
    realTime: Date.now(),
    awayTeamScore: Number(common.awayTeamScore),
    homeTeamScore: Number(common.homeTeamScore),
    diff,
    isHome: teamType === 'home',
    teamName: common[`${teamType}TeamName`],
    round: getConvertedRound(rootData),
  };

  await addMatchLog(matchId, logObj);
};

export const makeTeamSetScoreLog = async (
  matchId: string,
  rootData: ScoreboardData,
  diff: number,
  teamType: TeamType,
  point?: number,
) => {
  const { common, scoreboardHasSet } = rootData;
  if (!scoreboardHasSet) return;

  const round =
    point === -1
      ? getConvertedRound(rootData) + 1
      : getConvertedRound(rootData) - 1;

  const logObj: TeamSetScoreLogType = {
    type: 'TEAM_SET_SCORE',
    matchTime: 0,
    realTime: Date.now(),
    awayTeamSetScore: Number(scoreboardHasSet.awayTeamSetScore),
    homeTeamSetScore: Number(scoreboardHasSet.homeTeamSetScore),
    diff,
    isHome: teamType === 'home',
    teamName: common[`${teamType}TeamName`],
    round,
  };

  await addMatchLog(matchId, logObj);
};

export const makeTeamFoulLog = async (
  matchId: string,
  rootData: ScoreboardData,
  diff: number,
  teamType: TeamType,
) => {
  const { common, basketBall, time } = rootData;
  if (!basketBall) return;

  const logObj: TeamFoulLogType = {
    type: 'TEAM_FOUL',
    matchTime: getMatchTime(time),
    realTime: Date.now(),
    awayTeamFoul: Number(basketBall.awayTeamFoul),
    homeTeamFoul: Number(basketBall.homeTeamFoul),
    diff,
    isHome: teamType === 'home',
    teamName: common[`${teamType}TeamName`],
    round: getConvertedRound(rootData),
  };

  await addMatchLog(matchId, logObj);
};

export const makeTimeOutLog = async (
  matchId: string,
  rootData: ScoreboardData,
  teamType: TeamType,
) => {
  const { common, footBall, time } = rootData;
  if (!footBall) return;

  const logObj: TimeOutLogType = {
    type: 'TIMEOUT',
    matchTime: getMatchTime(time),
    realTime: Date.now(),
    homeTeamTimeOut: Number(footBall.homeTeamTimeOut),
    awayTeamTimeOut: Number(footBall.awayTeamTimeOut),
    isHome: teamType === 'home',
    teamName: common[`${teamType}TeamName`],
    round: getConvertedRound(rootData),
  };

  await addMatchLog(matchId, logObj);
};

const makePlayerScoreLog = async (
  matchId: string,
  rootData: ScoreboardData,
  player: MatchPlayer,
  point: number,
  teamType: TeamType,
) => {
  const { common, time } = rootData;
  const { name: playerName, id } = player;

  const logObj: PlayerScoreLogType = {
    type: 'PLAYER_SCORE',
    matchTime: getMatchTime(time),
    realTime: Date.now(),
    awayTeamScore: Number(common.awayTeamScore),
    homeTeamScore: Number(common.homeTeamScore),
    playerName,
    playerId: id as number,
    player,
    isHome: teamType === 'home',
    point,
    round: getConvertedRound(rootData),
  };

  await addMatchLog(matchId, logObj);
};

const makeCancelPlayerScoreLog = async (
  matchId: string,
  rootData: ScoreboardData,
  player: MatchPlayer,
  point: number,
  teamType: TeamType,
) => {
  const { common, time } = rootData;
  const { name: playerName, id } = player;

  const logObj: PlayerScoreLogType = {
    type: 'PLAYER_SCORE_CANCEL',
    matchTime: getMatchTime(time),
    realTime: Date.now(),
    awayTeamScore: Number(common.awayTeamScore),
    homeTeamScore: Number(common.homeTeamScore),
    playerName,
    playerId: id as number,
    isHome: teamType === 'home',
    point,
    round: getConvertedRound(rootData),
  };

  await addMatchLog(matchId, logObj);
};

const makePlayerFoulLog = async (
  matchId: string,
  rootData: ScoreboardData,
  player: MatchPlayer,
  teamType: TeamType,
  foulType?: FoulType | iceHockeyFoulType,
) => {
  const { time } = rootData;
  const { name: playerName, id } = player;

  const logObj: PlayerFoulLogType = {
    type: 'PLAYER_FOUL',
    matchTime: getMatchTime(time),
    realTime: Date.now(),
    playerName,
    playerId: id as number,
    isHome: teamType === 'home',
    foulType: foulType ?? '',
    round: getConvertedRound(rootData),
  };

  await addMatchLog(matchId, logObj);
};

const makeCancelPlayerFoulLog = async (
  matchId: string,
  rootData: ScoreboardData,
  player: MatchPlayer,
  teamType: TeamType,
  foulType: FoulType | iceHockeyFoulType,
) => {
  const { time } = rootData;
  const { name: playerName, id } = player;

  const logObj: PlayerFoulLogType = {
    type: 'PLAYER_FOUL_CANCEL',
    matchTime: getMatchTime(time),
    realTime: Date.now(),
    playerName,
    playerId: id as number,
    isHome: teamType === 'home',
    foulType,
    round: getConvertedRound(rootData),
  };

  await addMatchLog(matchId, logObj);
};

const makePlayerChangeLog = async (
  matchId: string,
  rootData: ScoreboardData,
  inPlayerInfo: string,
  outPlayerInfo: string,
  teamType: TeamType,
) => {
  const { time } = rootData;

  const logObj: PlayerChangeLogType = {
    type: 'CHANGE',
    matchTime: getMatchTime(time),
    realTime: Date.now(),
    inPlayerInfo,
    outPlayerInfo,
    isHome: teamType === 'home',
    round: getConvertedRound(rootData),
  };

  await addMatchLog(matchId, logObj);
};

const makePlayerOutLog = async (
  matchId: string,
  outPlayer: MatchPlayer,
  rootData: ScoreboardData,
  teamType: TeamType,
) => {
  const { time } = rootData;
  const { name, id } = outPlayer;
  const logObj: PlayerOutLogType = {
    type: 'PLAYER_OUT',
    matchTime: getMatchTime(time),
    realTime: Date.now(),
    isHome: teamType === 'home',
    playerId: id as number,
    playerName: name,
    round: getConvertedRound(rootData),
  };

  await addMatchLog(matchId, logObj);
};

const makeCancelPlayerOutLog = async (
  matchId: string,
  targetPlayer: MatchPlayer,
  rootData: ScoreboardData,
  teamType: TeamType,
) => {
  const { time } = rootData;
  const { name, id } = targetPlayer;
  const logObj: PlayerOutLogType = {
    type: 'PLAYER_OUT_CANCEL',
    matchTime: getMatchTime(time),
    realTime: Date.now(),
    isHome: teamType === 'home',
    playerId: id as number,
    playerName: name,
    round: getConvertedRound(rootData),
  };

  await addMatchLog(matchId, logObj);
};

export const makeQuarterEndLog = async (
  matchId: string,
  rootData: ScoreboardData,
  quarter: number,
) => {
  const { common } = rootData;

  const logObj: RoundEndLogType = {
    type: 'QUARTER_END',
    matchTime: Number.MAX_SAFE_INTEGER,
    realTime: Date.now(),
    awayTeamScore: Number(common.awayTeamScore),
    homeTeamScore: Number(common.homeTeamScore),
    round: quarter,
  };

  await addMatchLog(matchId, logObj);
};

export const makeQuarterStartLog = async (
  matchId: string,
  rootData: ScoreboardData,
) => {
  const logObj: RoundStartLogType = {
    type: 'QUARTER_START',
    matchTime: -1,
    realTime: Date.now(),
    round: getConvertedRound(rootData),
  };

  await addMatchLog(matchId, logObj);
};

export const makeInningEndLog = async (
  matchId: string,
  rootData: ScoreboardData,
  inning: number,
) => {
  const { common } = rootData;

  const logObj: RoundEndLogType = {
    type: 'INNING_END',
    matchTime: Number.MAX_SAFE_INTEGER,
    realTime: Date.now(),
    awayTeamScore: Number(common.awayTeamScore),
    homeTeamScore: Number(common.homeTeamScore),
    round: inning,
  };

  await addMatchLog(matchId, logObj);
};

export const makeInningStartLog = async (matchId: string, inning: number) => {
  const logObj: RoundStartLogType = {
    type: 'INNING_START',
    matchTime: -1,
    realTime: Date.now(),
    round: inning,
  };

  await addMatchLog(matchId, logObj);
};

export const makeSetEndLog = async (
  matchId: string,
  rootData: ScoreboardData,
  prevAwayTeamScore: number,
  prevHomeTeamScore: number,
  point?: number,
) => {
  const { scoreboardHasSet } = rootData;
  if (!scoreboardHasSet) return;

  const commonRound =
    Number(scoreboardHasSet.awayTeamSetScore) +
    Number(scoreboardHasSet.homeTeamSetScore);

  const round = point === 1 ? commonRound : commonRound + 2;

  const logObj: RoundEndLogType = {
    type: 'SET_END',
    matchTime: Number.MAX_SAFE_INTEGER,
    realTime: Date.now(),
    awayTeamScore: prevAwayTeamScore,
    homeTeamScore: prevHomeTeamScore,
    round,
  };

  await addMatchLog(matchId, logObj);
};

export const makeSetStartLog = async (
  matchId: string,
  rootData: ScoreboardData,
) => {
  const { scoreboardHasSet } = rootData;
  if (!scoreboardHasSet) return;

  const round =
    Number(scoreboardHasSet.awayTeamSetScore) +
    Number(scoreboardHasSet.homeTeamSetScore) +
    1;

  const logObj: RoundStartLogType = {
    type: 'SET_START',
    matchTime: -1,
    realTime: Date.now(),
    round,
  };

  await addMatchLog(matchId, logObj);
};

const getMatchTime = (rootTimeData: Time) => {
  const { isCountUp, setTime, statusOfTime, stopWatchStartTime } = rootTimeData;

  if (statusOfTime === 'READY') return 0;

  const calculatedMatchTime =
    statusOfTime === 'PAUSE'
      ? GV_pauseMatchTime
      : Math.floor(
          (Date.now() - GV_startTime - GV_stackPauseTime - 300) / 1000,
        );

  return isCountUp
    ? calculatedMatchTime + stopWatchStartTime
    : setTime - calculatedMatchTime;
};

const getFinalScore = (RDBData: ScoreboardData) => {
  const type = convertCommonType(RDBData.scoreBoard.type);
  switch (type) {
    case 'scoreboardHasSet':
      return {
        awayTeamScore: RDBData.scoreboardHasSet?.awayTeamSetScore,
        homeTeamScore: RDBData.scoreboardHasSet?.homeTeamSetScore,
      };
    case 'cricket': {
      return {
        awayTeamScore: RDBData.cricket?.target,
        homeTeamScore: RDBData.cricket?.total,
      };
    }
    case 'tennis': {
      return {
        awayTeamScore: RDBData.tennis?.awaySet,
        homeTeamScore: RDBData.tennis?.homeSet,
      };
    }
    case 'taekwondo': {
      const matchType = RDBData.taekwondo?.matchType;
      if (matchType !== 'bestOf3') {
        return {
          awayTeamScore: RDBData.common.awayTeamScore,
          homeTeamScore: RDBData.common.homeTeamScore,
        };
      }
      const round = RDBData.taekwondo?.round ?? 1;
      let homeTeamSetScore = 0;
      let awayTeamSetScore = 0;

      const awayPointRecord = RDBData.taekwondo?.awayPointRecord;
      const homePointRecord = RDBData.taekwondo?.homePointRecord;
      const matchStatus = RDBData.matchData.matchStatus;

      if (awayPointRecord && homePointRecord) {
        const endCondition = matchStatus === 'end' ? round : round - 1;
        for (let i = 0; i < endCondition; i++) {
          if (awayPointRecord[i] > homePointRecord[i]) {
            homeTeamSetScore++;
          } else if (awayPointRecord[i] < homePointRecord[i]) {
            awayTeamSetScore++;
          }
        }
      }

      return {
        awayTeamScore: awayTeamSetScore,
        homeTeamScore: homeTeamSetScore,
      };
    }
    case 'pickleball': {
      const set = RDBData.pickleball?.set ?? 1;
      let homeTeamSetScore = 0;
      let awayTeamSetScore = 0;

      const awayPointRecord = RDBData.pickleball?.awayPointRecord;
      const homePointRecord = RDBData.pickleball?.homePointRecord;
      const matchStatus = RDBData.matchData.matchStatus;

      if (awayPointRecord && homePointRecord) {
        const endCondition = matchStatus === 'end' ? set : set - 1;
        for (let i = 0; i < endCondition; i++) {
          if (awayPointRecord[i] < homePointRecord[i]) {
            homeTeamSetScore++;
          } else if (awayPointRecord[i] > homePointRecord[i]) {
            awayTeamSetScore++;
          }
        }
      }

      return {
        awayTeamScore: awayTeamSetScore,
        homeTeamScore: homeTeamSetScore,
      };
    }

    default: {
      return {
        awayTeamScore: RDBData.common.awayTeamScore,
        homeTeamScore: RDBData.common.homeTeamScore,
      };
    }
  }
};
