import type { ReactNode } from 'react';

import { ActiveAuthProvider } from 'contexts/ActiveAuthContext';
import { AuthProvider } from 'contexts/AuthContext';
import { ManagerAuthProvider } from 'contexts/ManagerAuthContext';

import ActiveAuthService from './Auth/ActiveAuthService';
import AuthService from './Auth/AuthService';
import ManagerAuthService from './Auth/ManagerAuthService';

const AuthenticationProviderStack = ({
  children,
  customToken,
}: {
  children: ReactNode;
  customToken?: string;
}) => {
  return (
    <AuthProvider>
      <ManagerAuthProvider>
        <ActiveAuthProvider>
          <AuthService customToken={customToken}>
            <ManagerAuthService>
              <ActiveAuthService>{children}</ActiveAuthService>
            </ManagerAuthService>
          </AuthService>
        </ActiveAuthProvider>
      </ManagerAuthProvider>
    </AuthProvider>
  );
};

export default AuthenticationProviderStack;
