import { useContext } from 'react';
import { CookieContext } from 'contexts/CookieContext';

const useCookie = () => {
  const context = useContext(CookieContext);

  if (!context)
    throw new Error('CookieContext must be placed within CookieProvider');

  return context;
};

export default useCookie;
