import type { ReactNode } from 'react';

import useFetchUser from 'hooks/useFetchUser';
import useManagerAuthentication from 'hooks/useManagerAuthentication';

const ManagerAuthService = ({ children }: { children: ReactNode }) => {
  useManagerAuthentication();
  useFetchUser({
    role: 'manager',
  });

  return <>{children}</>;
};

export default ManagerAuthService;
