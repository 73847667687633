import type { ReactNode} from 'react';
import { useEffect } from 'react';

import { setUser } from '@sentry/nextjs';

import useActiveAuth from 'hooks/useActiveAuth';
import useSubscriptionStatusListener from 'hooks/useSubscriptionStatusListener';

const ActiveAuthService = ({ children }: { children: ReactNode }) => {
  const { channel, user, onUserSnapshotUpdate } = useActiveAuth();

  useEffect(() => {
    const username = channel?.channelName || user?.displayName;
    const id = user?.id;

    if (id && username)
      setUser({
        id,
        username,
      });
  }, [channel?.channelName, user?.displayName, user?.id]);

  useSubscriptionStatusListener({
    uid: user.id,
    onUserSnapshotUpdate: onUserSnapshotUpdate,
  });

  return <>{children}</>;
};

export default ActiveAuthService;
