import type { PaletteMode, Theme } from '@mui/material';
import type { ThemeOptions } from '@mui/material/styles/createTheme';

import getButtonStyle from './button';
import palette, {
  BLACK,
  WHITE,
  deprecatedGrayPalette,
  primaryPalette,
  secondaryPalette,
} from './palette';
import { typography } from './typography';

type CustomThemeOptions = ThemeOptions & { mode?: PaletteMode };

export const getDesignTokens = (mode: PaletteMode): CustomThemeOptions => ({
  ...typography,
  mode,
  palette: palette(mode),
  transparent: 'rgba(255, 255, 255, 0)',
  components: {
    MuiButton: getButtonStyle(mode),
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          background: mode === 'light' ? '#fafafa' : BLACK,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          background: mode === 'light' ? WHITE : BLACK,
        },
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          fontFamily: typography.typography.fontFamily,
          color: mode === 'light' ? 'grayPalette.15' : 'grayPalette.90',
          '&.Mui-selected': {
            color: theme.palette.text.bold,
          },
          '& > svg.MuiTab-iconWrapper': {
            marginLeft: '4px',
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: primaryPalette[500],
        },
        colorSecondary: {
          backgroundColor: secondaryPalette[2],
        },
        colorWarning: {
          backgroundColor: deprecatedGrayPalette[mode][30],
          color: WHITE,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor:
            mode === 'light' ? WHITE : deprecatedGrayPalette[mode][10],
          backgroundImage: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.background.default,
          borderRadius: 0,
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          borderRadius: '10px',
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.hover,
            '&:hover': {
              backgroundColor: theme.palette.action.focus,
            },
          },
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          height: '24px',
          width: '24px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '38px',
          height: '20px',
          padding: 0,
          marginLeft: 8,
        },
        thumb: {
          width: '16px',
          height: '16px',
        },
        switchBase: {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: WHITE,
            '& + .MuiSwitch-track': {
              backgroundColor: 'primaryColor.500',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': { opacity: 0.5 },
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: mode === 'light' ? 0.7 : 0.3,
          },
        },
        track: {
          borderRadius: 26 / 2,
          backgroundColor: mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transitionDuration: '500ms',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingBottom: '12px',
          paddingTop: '12px',
          MuiSelect: {
            styleOverrides: {
              paper: {
                height: '200px',
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          borderRadius: '8px',
          backgroundColor: theme.palette.background.paper,
          backgroundImage: 'none',
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor:
              mode === 'light'
                ? 'rgba(213, 243, 219, 0.3)'
                : 'rgba(22, 77, 34, 0.3)',
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1Bold: 'h1',
          h2Bold: 'h2',
          h3Bold: 'h3',
          h4Bold: 'h4',
          h5Bold: 'h5',
          h6Bold: 'h6',

          subtitle1Bold: 'h6',
          subtitle2Bold: 'h6',

          body1Bold: 'p',
          body2Bold: 'p',
          body3: 'p',
          body3Bold: 'p',
          body4: 'p',
          body4Bold: 'p',

          tab1: 'nav',
          tab1Bold: 'nav',
          tab2: 'nav',
          tab2Bold: 'nav',
          tab3: 'nav',
          tab3Bold: 'nav',
          tab4: 'nav',
          tab4Bold: 'nav',

          chip: 'p',
          overline: 'p',
          overlineBold: 'p',

          score: 'p',
          buttonSmall: 'button',
          buttonMedium: 'button',
          buttonLarge: 'button',
        },
      },
    },
  },
});
