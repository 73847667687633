import { useContext } from 'react';

import { ActiveAuthContext } from 'contexts/ActiveAuthContext';

const useActiveAuth = () => {
  const context = useContext(ActiveAuthContext);

  if (!context)
    throw new Error('ActiveAuthContext must be placed within AuthProvider');

  return context;
};

export default useActiveAuth;
