import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { Button, Typography, styled } from '@mui/material';

const Wrapper = styled('div')`
  text-align: center;
  padding: ${({ theme }) =>
    theme.breakpoints.down('md') ? theme.spacing(6) : theme.spacing(10)};
`;

const ErrorRefresh = () => {
  const router = useRouter();
  const { t } = useTranslation('common');

  return (
    <Wrapper>
      <Typography
        variant="h3"
        gutterBottom
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {t('error.title')}
        <ErrorOutline color="error" sx={{ ml: 1 }} />
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {t('error.description')}
      </Typography>
      <Button
        variant="contained"
        sx={{ marginTop: '1rem' }}
        onClick={() => router.reload()}
      >
        {t('error.button')}
      </Button>
    </Wrapper>
  );
};

export default ErrorRefresh;
