import Script from 'next/script';

import useActiveAuth from 'hooks/useActiveAuth';

const GoogleAdsScript = () => {
  const { isProfileLoadProcessComplete } = useActiveAuth();

  return isProfileLoadProcessComplete ? (
    <Script
      id="Adsense"
      async
      onError={(e) => {
        console.error('Ad script error', e);
      }}
      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4449874113484547"
      crossOrigin="anonymous"
    />
  ) : null;
};

export default GoogleAdsScript;
