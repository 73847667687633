import { FirebaseError } from 'firebase/app';

const errorMessage = (error: unknown) => {
  let errorMessage = 'Unknown Error';
  if (error instanceof FirebaseError) {
    errorMessage = `${(error.code.split('/')[1] ?? error.code).replaceAll(
      '-',
      ' ',
    )}`;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }
  return (
    errorMessage.substring(0, 1).toUpperCase() +
    errorMessage.substring(1, errorMessage.length)
  );
};

export default errorMessage;
