import { useEffect } from 'react';

import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { enqueueSnackbar } from 'notistack';

import type { FireStoreStudioUserType } from 'types/firestore';

import errorMessage from 'utils/errorMessage';

interface UseSubscriptionStatusListenerProps {
  uid?: string;
  onUserSnapshotUpdate: (data: FireStoreStudioUserType) => void;
}

const useSubscriptionStatusListener = ({
  uid,
  onUserSnapshotUpdate,
}: UseSubscriptionStatusListenerProps) => {
  const db = getFirestore();

  useEffect(() => {
    if (uid) {
      const unsubscribe = onSnapshot(doc(db, 'studio_users', uid), (doc) => {
        try {
          const data = doc.data() as FireStoreStudioUserType;
          onUserSnapshotUpdate(data);
        } catch (error) {
          enqueueSnackbar(errorMessage(error), {
            variant: 'error',
            persist: true,
          });
        }
      });

      return unsubscribe;
    }
  }, [db, onUserSnapshotUpdate, uid]);
};

export default useSubscriptionStatusListener;
