import type { Firestore } from 'firebase/firestore';
import {
  type DocumentData,
  type DocumentReference,
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore/lite';

import type { CaptionEventType } from 'types/caption';
import type { ObsConnectionData } from 'types/connect';
import type {
  CaptionEventsDataType,
  FireStoreStudioUserType,
} from 'types/firestore';
import type { HotkeyListType } from 'types/hotkey';
import type { LogObjType } from 'types/matchData';
import type {
  ScoreboardData,
  ShortcutBtnType,
  SportType,
} from 'types/scoreboardData';

import { deleteRDB } from './RDB/util';
import { deleteAvatarImg } from './storage';

let globalFirestore: Firestore;
let globalMatchLogRef: DocumentReference<DocumentData>;
let currentMatchId: string;

const initGlobalFirestore = () => {
  globalFirestore = getFirestore();
};

const initMatchLogRef = (db: Firestore, matchId: string) => {
  if (currentMatchId === undefined || currentMatchId !== matchId) {
    currentMatchId = matchId;
  }

  if (db !== globalFirestore) {
    initGlobalFirestore();
  }

  globalMatchLogRef = doc(globalFirestore, 'matchLog', currentMatchId);
};

const checkMatchLogFirestore = (matchId: string) => {
  if (globalFirestore === undefined) {
    initGlobalFirestore();
  }

  if (globalMatchLogRef === undefined || currentMatchId !== matchId) {
    initMatchLogRef(globalFirestore, matchId);
  }
};

export const getUserSubscriptionInfo = async (uid: string) => {
  const db = getFirestore();
  const docSnap = await getDoc(doc(db, 'studio_users', uid));
  return docSnap.data() as FireStoreStudioUserType;
};

export const getStoreMatchData = async (matchId: string) =>
  (await getDoc(doc(getFirestore(), `matches/${matchId}`))).data();

const checkMatchExists = async (matchId: string) =>
  (await getDoc(doc(getFirestore(), `matches/${matchId}`))).exists();

/**
 * 유저 파악을 위한 유저데이터, 최종 스코어 계산(updateStoreTeamScore)을 위한 경기 종목을 받아 Firestore에 경기를 생성하는 함수
 * @param uid 유저 ID
 * @param userName 유저 이름
 * @param sportType 경기 종목
 * @returns Firestore에서 생성한 랜덤 경기 ID
 */
export const createMatchData = async (
  uid: string,
  userName: string,
  sportType: SportType,
) => {
  const matchRef = await addDoc(collection(getFirestore(), 'matches'), {
    RDB: {},
    createdAt: serverTimestamp(),
    sportType,
    uid,
    updatedAt: serverTimestamp(),
    userName,
  });

  return matchRef.id;
};

export const updateStoreRDB = async (
  matchId: string,
  RDB: ScoreboardData | Record<string, never>,
) => {
  const isExists = await checkMatchExists(matchId);
  if (!isExists) throw new Error('Matchdata in firstore is not exists.');

  try {
    const db = getFirestore();
    await updateDoc(doc(db, 'matches', matchId), {
      RDB,
      updatedAt: serverTimestamp(),
    });
  } catch {
    throw new Error('Update RDB to firestore is failed.');
  }
};

/**
 * 경기 수정 시 종목이 변경되는 경우, 종목과 관련된 데이터를 초기화 / 업데이트 하는 함수
 * 1. Firestore의 RDB 초기화, 종목 업데이트
 * 2. 로그 기록 삭제
 * 3. RDB 삭제
 * @param matchId 경기 ID
 * @param sportType 경기 종목
 */
export const updateSportTypeChange = async (
  matchId: string,
  sportType: SportType,
) => {
  try {
    const isExists = await checkMatchExists(matchId);
    if (!isExists) throw new Error('Matchdata in firstore is not exists.');
    const db = getFirestore();
    await updateDoc(doc(db, 'matches', matchId), {
      RDB: {},
      sportType,
      updatedAt: serverTimestamp(),
    });
  } catch {
    throw new Error('Update sportType to firestore is failed.');
  }

  try {
    await resetMatchLog(matchId);
  } catch {
    throw new Error('Reset matchLog is failed.');
  }

  try {
    await deleteRDB(matchId);
  } catch {
    throw new Error('Delete RDB is failed.');
  }
};

export const deleteStoreMatchData = async (matchId: string) => {
  const isExists = await checkMatchExists(matchId);
  if (!isExists) return;
  const db = getFirestore();
  try {
    await deleteDoc(doc(db, 'matches', matchId));
    await deleteDoc(doc(db, 'matchLog', matchId));
    await deleteRDB(matchId);
  } catch (err) {
    console.error('Delete matchdata in firestore failed.', err);
  }

  deleteAvatarImg(matchId);
};

export const initLog = async (matchId: string, sportType: SportType) => {
  checkMatchLogFirestore(matchId);
  const matchLogDoc = await getDoc(globalMatchLogRef);
  if (!matchLogDoc.exists()) {
    setDoc(globalMatchLogRef, {
      log: [],
      sportType,
      matchId,
      updatedAt: serverTimestamp(),
    });
    return;
  }
};

export const getMatchLog = async (matchId: string): Promise<LogObjType[]> => {
  checkMatchLogFirestore(matchId);

  const logSnap = await getDoc(globalMatchLogRef);
  return logSnap.data()?.log ?? [];
};

const resetMatchLog = async (matchId: string) =>
  await deleteDoc(doc(getFirestore(), 'matchLog', matchId));

export const addMatchLog = async (matchId: string, newLog: LogObjType) => {
  checkMatchLogFirestore(matchId);

  await updateDoc(globalMatchLogRef, {
    log: arrayUnion(newLog),
    updatedAt: serverTimestamp(),
  });
};

export const updateMatchLog = async (
  matchId: string,
  updatedLogs: LogObjType[],
) => {
  checkMatchLogFirestore(matchId);

  await updateDoc(globalMatchLogRef, {
    log: updatedLogs,
    updatedAt: serverTimestamp(),
  });
};

export const changeMatchLogTeam = async (matchId: string) => {
  checkMatchLogFirestore(matchId);

  const matchLogDoc = await getDoc(globalMatchLogRef);
  const matchLogData = matchLogDoc.data();

  if (matchLogData?.log) {
    const changedMatchLog = matchLogData.log.map((log: LogObjType) => {
      const changedLog = { ...log };
      if ('isHome' in log) {
        changedLog.isHome = !log.isHome;
      }
      if ('homeTeamScore' in log && 'awayTeamScore' in log) {
        const tempHomeTeamScore = log.homeTeamScore;
        changedLog.homeTeamScore = log.awayTeamScore;
        changedLog.awayTeamScore = tempHomeTeamScore;
      }
      return changedLog;
    });

    await updateDoc(globalMatchLogRef, {
      log: changedMatchLog,
      updatedAt: serverTimestamp(),
    });
  }
};

export const deleteMatchLog = async (
  matchId: string,
  deletedLog: LogObjType,
) => {
  checkMatchLogFirestore(matchId);

  await updateDoc(globalMatchLogRef, {
    log: arrayRemove(deletedLog),
    updatedAt: serverTimestamp(),
  });
};

export const getCaptionsInfo = async (uid: string) => {
  const db = getFirestore();
  const captionsDocSnap = await getDoc(doc(db, 'captions', uid));

  return captionsDocSnap.data() as CaptionEventsDataType;
};

export const getEndedCaptionEvents = async (
  uid: string,
): Promise<CaptionEventType[]> => {
  const db = getFirestore();
  const captionsDocSnap = await getDoc(doc(db, 'captions', uid));

  return captionsDocSnap.get('data');
};

export const updateUserNameOnMatch = async (uid: string, newName: string) => {
  const db = getFirestore();
  const matchRef = collection(db, 'matches');

  const q = query(matchRef, where('uid', '==', uid));

  const matchDataByUid = await getDocs(q);

  matchDataByUid.forEach(async (item) => {
    await setDoc(
      doc(db, 'matches', item.id),
      { userName: newName },
      { merge: true },
    );
  });
};

export const updateHotkeyList = async (
  uid: string,
  type: SportType,
  hotkeyList: HotkeyListType,
) => {
  const db = getFirestore();
  const hotkeyRef = doc(db, 'studio_users_hotkey', uid);
  const hotkeySnap = await getDoc(hotkeyRef);
  try {
    if (hotkeySnap.exists()) {
      await updateDoc(hotkeyRef, {
        [`hotkeyList.${type}`]: hotkeyList,
      });
    } else {
      await setDoc(hotkeyRef, {
        uid,
        hotkeyList: {
          [`${type}`]: hotkeyList,
        },
      });
    }
  } catch {
    throw new Error('Update hotkey to firestore is failed.');
  }
};

export const getObsConnectData = async (
  uid: string,
): Promise<ObsConnectionData | undefined> => {
  const db = getFirestore();
  const obsConnectDocSnap = await getDoc(doc(db, 'studio_connect', uid));
  return obsConnectDocSnap.data()?.obs;
};

export const updateComartServerIp = async (uid: string, serverIp: string) => {
  const db = getFirestore();
  const connectRef = doc(db, 'studio_connect', uid);

  await updateDoc(connectRef, {
    comart: {
      serverIp,
    },
  });
};

export const getComartServerIp = async (uid: string): Promise<string> => {
  const db = getFirestore();
  const connectRef = doc(db, 'studio_connect', uid);
  const connectSnap = await getDoc(connectRef);
  return connectSnap.data()?.comart?.serverIp ?? '';
};

export const getShortcutBtns = async (
  uid: string,
): Promise<ShortcutBtnType[] | undefined> => {
  const db = getFirestore();
  const connectRef = doc(db, 'studio_connect', uid);
  const connectSnap = await getDoc(connectRef);
  return connectSnap.data()?.shortcutBtns;
};

export const updateShortcutBtns = async (
  uid: string,
  shortcutBtnList: ShortcutBtnType[],
) => {
  const db = getFirestore();
  const connectRef = doc(db, 'studio_connect', uid);

  await updateDoc(connectRef, {
    shortcutBtns: shortcutBtnList,
  });
};

export const getUseShortcut = async (uid: string): Promise<boolean> => {
  const db = getFirestore();
  const connectRef = doc(db, 'studio_connect', uid);
  const connectSnap = await getDoc(connectRef);
  if (!connectSnap.exists()) {
    setDoc(connectRef, { uid, useShortcut: false });
    return false;
  }
  return connectSnap.data()?.useShortcut ?? false;
};

export const updateUseShortcut = async (uid: string, useShortcut: boolean) => {
  const db = getFirestore();
  const connectRef = doc(db, 'studio_connect', uid);
  const connectSnap = await getDoc(connectRef);

  if (!connectSnap.exists()) {
    setDoc(connectRef, { uid, useShortcut });
    return;
  }

  await updateDoc(connectRef, {
    useShortcut,
  });
};
