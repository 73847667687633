import type { ReactNode} from 'react';
import { createContext, useRef, useState } from 'react';

import type { HotkeyContextType, HotkeyListType } from 'types/hotkey';

const HotkeyContext = createContext<HotkeyContextType | null>(null);

const HotkeyProvider = ({ children }: { children: ReactNode }) => {
  const hotkeyBoxRef = useRef<HTMLDivElement>(null);

  const [isHotkeyOn, setIsHotkeyOn] = useState(false);
  const [isHotkeySettingMode, setIsHotkeySettingMode] = useState(false);
  const [localHotkeyList, setLocalHotkeyList] = useState<HotkeyListType>({});
  const [storeHotkeyList, setStoreHotkeyList] = useState<HotkeyListType>({});
  const [showHotkeyDialog, setShowHotkeyDialog] = useState(false);
  const [selectedButton, setSelectedButton] = useState<string>('');

  const updateIsHotkeyOn = (state: boolean) => {
    setIsHotkeyOn(state);
  };

  const updateIsHotkeySettingMode = (state: boolean) => {
    setIsHotkeySettingMode(state);
  };

  const updateLocalHotkeyList = (list: HotkeyListType) => {
    setLocalHotkeyList(list);
  };

  const updateStoreHotkeyList = (list: HotkeyListType) => {
    setStoreHotkeyList(list);
  };

  const updateShowHotkeyDialog = (state: boolean) => {
    setShowHotkeyDialog(state);
  };

  const updateSelectedButton = (id: string) => {
    setSelectedButton(id);
  };

  return (
    <HotkeyContext.Provider
      value={{
        hotkeyBoxRef,
        isHotkeyOn,
        isHotkeySettingMode,
        localHotkeyList,
        storeHotkeyList,
        showHotkeyDialog,
        selectedButton,
        updateIsHotkeyOn,
        updateIsHotkeySettingMode,
        updateLocalHotkeyList,
        updateStoreHotkeyList,
        updateShowHotkeyDialog,
        updateSelectedButton,
      }}
    >
      {children}
    </HotkeyContext.Provider>
  );
};

export { HotkeyContext, HotkeyProvider };
