import { useContext } from 'react';

import { ManagerAuthContext } from 'contexts/ManagerAuthContext';

const useManagerAuth = () => {
  const context = useContext(ManagerAuthContext);

  if (!context)
    throw new Error('ManagerAuthContext must be placed within AuthProvider');

  return context;
};

export default useManagerAuth;
