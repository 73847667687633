import type { ReactNode} from "react";
import { createContext, useCallback, useMemo, useState } from "react";
import type { CookieContextType } from "types/cookie";

const CookieContext = createContext<CookieContextType>(null);
const CookieProvider = ({ children, cookies }: { children: ReactNode, cookies: Record<string, string> }) => {
    const [cookie] = useState(cookies);
    const getCookie = useCallback((cookieName: string) => {
        return cookie[cookieName] || null;
    }
    , [cookie]);
    const value = useMemo(() => ({
        getCookie,
    }), [getCookie]);
    return <CookieContext.Provider value={value}>{children}</CookieContext.Provider>;
}
export { CookieContext , CookieProvider };