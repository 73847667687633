import type { ReactNode } from 'react';
import { createContext, useState } from 'react';

import type { ScoreboardData, WidgetImgURL } from 'types/scoreboardData';

import { initialScoreboardData } from 'utils/getInitialScoreboardData';

interface ScoreboardContextType {
  scoreboardData: ScoreboardData;
  matchId: string;
  widgetImgUrlList: WidgetImgURL[];
  initScoreboardData: (initData: ScoreboardData) => void;
  initMatchId: (matchId: string) => void;
  initWidgetImgUrlList: (widgetImgURLs: WidgetImgURL[]) => void;
  updateScoreboardData: (key: string, value: object) => void;
}

const ScoreboardContext = createContext<ScoreboardContextType | null>(null);

const ScoreboardProvider = ({ children }: { children: ReactNode }) => {
  const [matchId, setMatchId] = useState<string>('');
  const [scoreboardData, setScoreboardData] = useState<ScoreboardData>(
    initialScoreboardData,
  );
  const [widgetImgUrlList, setWidgetImgUrlList] = useState<WidgetImgURL[]>([]);

  const initScoreboardData = (initData: ScoreboardData) => {
    setScoreboardData(initData);
  };

  const initMatchId = (matchId: string) => {
    setMatchId(matchId);
  };

  const initWidgetImgUrlList = (widgetImgURLs: WidgetImgURL[]) => {
    setWidgetImgUrlList([...widgetImgURLs]);
  };

  const updateScoreboardData = (key: string, value: object) => {
    setScoreboardData((cur) => ({ ...cur, [`${key}`]: value }));
  };

  return (
    <ScoreboardContext.Provider
      value={{
        scoreboardData,
        matchId,
        widgetImgUrlList,
        initScoreboardData,
        initMatchId,
        initWidgetImgUrlList,
        updateScoreboardData,
      }}
    >
      {children}
    </ScoreboardContext.Provider>
  );
};

export { ScoreboardContext, ScoreboardProvider };
