const calcRem = (size: number) => `${size / 16}rem`;

export const typography = {
  typography: {
    fontFamily: [
      'Pretendard',
      '"Pretendard Variable"',
      '-apple-system',
      'BlinkMacSystemFont',
      'system-ui',
      'Roboto',
      '"Helvetica Neue"',
      '"Segoe UI"',
      '"Apple SD Gothic Neo"',
      '"Noto Sans KR"',
      '"Malgun Gothic"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      'sans-serif',
    ].join(','),
    fontSize: 16,
    h1: {
      fontSize: calcRem(34),
      fontWeight: 400,
      lineHeight: '150%',
    },
    h1Bold: {
      fontSize: calcRem(34),
      fontWeight: 700,
      lineHeight: '150%',
    },
    h2: {
      fontSize: calcRem(28),
      fontWeight: 400,
      lineHeight: '150%',
    },
    h2Bold: {
      fontSize: calcRem(28),
      fontWeight: 700,
      lineHeight: '150%',
    },
    h3: {
      fontSize: calcRem(24),
      fontWeight: 400,
      lineHeight: '150%',
    },
    h3Bold: {
      fontSize: calcRem(24),
      fontWeight: 700,
      lineHeight: '150%',
    },
    h4: {
      fontSize: calcRem(20),
      fontWeight: 400,
      lineHeight: '150%',
    },
    h4Bold: {
      fontSize: calcRem(20),
      fontWeight: 700,
      lineHeight: '150%',
    },
    h5: {
      fontSize: calcRem(16),
      fontWeight: 400,
      lineHeight: '150%',
    },
    h5Bold: {
      fontSize: calcRem(16),
      fontWeight: 700,
      lineHeight: '150%',
    },
    h6: {
      fontSize: calcRem(14),
      fontWeight: 400,
      lineHeight: '150%',
    },
    h6Bold: {
      fontSize: calcRem(14),
      fontWeight: 700,
      lineHeight: '150%',
    },

    body1: {
      fontSize: calcRem(16),
      fontWeight: 400,
      lineHeight: '150%',
    },
    body1Bold: {
      fontSize: calcRem(16),
      fontWeight: 700,
      lineHeight: '150%',
    },
    body2: {
      fontSize: calcRem(14),
      fontWeight: 400,
      lineHeight: '150%',
    },
    body2Bold: {
      fontSize: calcRem(14),
      fontWeight: 700,
      lineHeight: '150%',
    },
    body3: {
      fontSize: calcRem(12),
      fontWeight: 400,
      lineHeight: '150%',
    },
    body3Bold: {
      fontSize: calcRem(12),
      fontWeight: 700,
      lineHeight: '150%',
    },
    body4: {
      fontSize: calcRem(10),
      fontWeight: 400,
      lineHeight: '150%',
    },
    body4Bold: {
      fontSize: calcRem(10),
      fontWeight: 700,
      lineHeight: '150%',
    },

    tab1: {
      fontSize: calcRem(20),
      fontWeight: 400,
      lineHeight: '150%',
    },
    tab1Bold: {
      fontSize: calcRem(20),
      fontWeight: 700,
      lineHeight: '150%',
    },
    tab2: {
      fontSize: calcRem(18),
      fontWeight: 400,
      lineHeight: '150%',
    },
    tab2Bold: {
      fontSize: calcRem(18),
      fontWeight: 700,
      lineHeight: '150%',
    },
    tab3: {
      fontSize: calcRem(16),
      fontWeight: 400,
      lineHeight: '150%',
    },
    tab3Bold: {
      fontSize: calcRem(16),
      fontWeight: 700,
      lineHeight: '150%',
    },
    tab4: {
      fontSize: calcRem(14),
      fontWeight: 400,
      lineHeight: '150%',
    },
    tab4Bold: {
      fontSize: calcRem(14),
      fontWeight: 700,
      lineHeight: '150%',
    },

    subtitle1: {
      fontSize: calcRem(16),
      fontWeight: 600,
      lineHeight: '140%',
    },
    subtitle1Bold: {
      fontSize: calcRem(16),
      fontWeight: 600,
      lineHeight: '140%',
    },
    subtitle2: {
      fontSize: calcRem(14),
      fontWeight: 700,
      lineHeight: '140%',
    },
    subtitle2Bold: {
      fontSize: calcRem(14),
      fontWeight: 700,
      lineHeight: '140%',
    },
    score: {
      fontSize: calcRem(56),
      fontWeight: 700,
      lineHeight: '150%',
    },

    overline: {
      fontSize: calcRem(12),
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: 'normal',
      textTransform: 'none' as const,
    },
    overlineBold: {
      fontSize: calcRem(12),
      fontWeight: 700,
      lineHeight: '150%',
    },
    chip: {
      fontSize: calcRem(13),
      fontWeight: 600,
      lineHeight: '150%',
    },
    buttonSmall: {
      fontSize: calcRem(12),
      fontWeight: 600,
      lineHeight: '150%',
    },
    buttonMedium: {
      fontSize: calcRem(14),
      fontWeight: 600,
      lineHeight: '150%',
    },
    buttonLarge: {
      fontSize: calcRem(16),
      fontWeight: 600,
      lineHeight: '150%',
    },
  },
};
