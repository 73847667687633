import { runTransaction, update } from 'firebase/database';

import type {
  ScoreboardData,
  TeamType,
  scoreboardHasSet,
} from 'types/scoreboardData';

import { initialScoreboardHasSetData } from 'utils/getInitialScoreboardData';

import {
  getRef,
  makeSetEndLog,
  makeSetStartLog,
  makeTeamSetScoreLog,
} from './util';

export const updateTeamSetScore = async (
  matchId: string,
  key: string,
  setScore: string,
  teamType: TeamType,
) => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  let diff = 0;
  let prevAwayTeamScore = 0;
  let prevHomeTeamScore = 0;

  const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
    if (rootData && rootData.scoreboardHasSet) {
      prevAwayTeamScore = Number(rootData.common.awayTeamScore);
      prevHomeTeamScore = Number(rootData.common.homeTeamScore);
      diff = Number(setScore) - Number(rootData.scoreboardHasSet[key]);
      rootData.scoreboardHasSet[key] = setScore;
      rootData.common.awayTeamScore = '00';
      rootData.common.homeTeamScore = '00';
    }
    return rootData;
  });

  if (diff !== 0) {
    const rootData = result.snapshot.val() as ScoreboardData;
    await makeTeamSetScoreLog(matchId, rootData, diff, teamType);
    await makeSetEndLog(
      matchId,
      rootData,
      prevAwayTeamScore,
      prevHomeTeamScore,
    );
    await makeSetStartLog(matchId, rootData);
  }
};

export const resetSetScore = async (matchId: string, teamType: TeamType) => {
  const scoreboardHasSetRef = getRef(`scoreboard/${matchId}/scoreboardHasSet`);
  await update(
    scoreboardHasSetRef,
    teamType === 'home' ? { homeTeamSetScore: '0' } : { awayTeamSetScore: '0' },
  );
};

export const updateSetScoreCustomPoint = async (
  matchId: string,
  teamType: TeamType,
  point: number,
) => {
  const rootRef = getRef(`scoreboard/${matchId}`);
  let diff = 0;
  let updatedSetScore = 0;
  let prevAwayTeamScore = 0;
  let prevHomeTeamScore = 0;

  const result = await runTransaction(rootRef, (rootData: ScoreboardData) => {
    if (rootData && rootData.scoreboardHasSet) {
      prevAwayTeamScore = Number(rootData.common.awayTeamScore);
      prevHomeTeamScore = Number(rootData.common.homeTeamScore);

      const preSetScore = Number(
        rootData.scoreboardHasSet[`${teamType}TeamSetScore`],
      );
      updatedSetScore = preSetScore + point;
      if (updatedSetScore < 0) return;
      diff = updatedSetScore - preSetScore;
      rootData.scoreboardHasSet[`${teamType}TeamSetScore`] =
        `${updatedSetScore}`;
      rootData.common.awayTeamScore = '00';
      rootData.common.homeTeamScore = '00';
    }
    return rootData;
  });

  if (updatedSetScore >= 0) {
    const rootData = result.snapshot.val() as ScoreboardData;
    await makeTeamSetScoreLog(matchId, rootData, diff, teamType, point);
    await makeSetEndLog(
      matchId,
      rootData,
      prevAwayTeamScore,
      prevHomeTeamScore,
      point,
    );
    await makeSetStartLog(matchId, rootData);
  }
};

export const updateScoreboardHasSetInitData = async (matchId: string) => {
  const scoreboardHasSetRef = getRef(`scoreboard/${matchId}/scoreboardHasSet`);
  await runTransaction(
    scoreboardHasSetRef,
    (scoreboardHasSetData: scoreboardHasSet) => {
      if (scoreboardHasSetData) {
        const {
          awayTeamSetScore,
          homeTeamSetScore,
          serve,
          isServeShow,
          serveBegins,
          serveSwitchPoint,
          endPoint,
          isDeuce,
        } = scoreboardHasSetData;

        const updatedData = {
          awayTeamSetScore:
            awayTeamSetScore ?? initialScoreboardHasSetData.awayTeamSetScore,
          homeTeamSetScore:
            homeTeamSetScore ?? initialScoreboardHasSetData.homeTeamSetScore,
          serve: serve ?? initialScoreboardHasSetData.serve,
          isServeShow: isServeShow ?? initialScoreboardHasSetData.isServeShow,
          serveBegins: serveBegins ?? initialScoreboardHasSetData.serveBegins,
          serveSwitchPoint:
            serveSwitchPoint ?? initialScoreboardHasSetData.serveSwitchPoint,
          endPoint: endPoint ?? initialScoreboardHasSetData.endPoint,
          isDeuce: isDeuce ?? initialScoreboardHasSetData.isDeuce,
        };
        return updatedData;
      }
      return scoreboardHasSetData;
    },
  );
};

type scoreboardHasSetData = TeamType | boolean | string | number;

export const updateScoreboardHasSetData = async (
  matchId: string,
  childKey: string,
  data: scoreboardHasSetData,
) => {
  const scoreboardHasSetRef = getRef(`scoreboard/${matchId}/scoreboardHasSet`);
  await update(scoreboardHasSetRef, {
    [`${childKey}`]: data,
  });
};
