const sendMessageToServiceWorker = async (message: string) => {
  try {
    if (!('serviceWorker' in navigator)) {
      throw new Error('Service Worker is not supported');
    }

    const registration = await navigator.serviceWorker.ready;

    if (!registration.active) {
      throw new Error('Service Worker is not active');
    }

    registration.active.postMessage(message);
  } catch (error) {
    console.error(error);
  }
};

export default sendMessageToServiceWorker;
