import { useEffect } from 'react';

import useActiveAuth from 'hooks/useActiveAuth';
import useAuth from 'hooks/useAuth';

import { addEventToDataLayer } from 'utils/forGTM/dataLayerHandlerForGTM';

const TRY_LOGIN_EVENT = 'Try Login';

const useTryLoginEventForGTM = () => {
  const { user: owner_user, isSubscribedUser: owner_isSubscribedUser } =
    useAuth();
  const {
    isProfileLoadProcessing,
    isAnonymousUser,
    role,
    user,
    isSubscribedUser,
  } = useActiveAuth();

  useEffect(() => {
    if (isProfileLoadProcessing) return;

    if (isAnonymousUser) {
      addEventToDataLayer({
        event: TRY_LOGIN_EVENT,
        user_id: undefined,
        isSubscribe: false,
        trialing: false,
      });
    } else {
      addEventToDataLayer({
        event: TRY_LOGIN_EVENT,
        isMyAccount: role === 'owner',

        user_id: owner_user?.id,
        isSubscribe: owner_isSubscribedUser,
        trialing: Boolean(owner_user?.membershipStatus === 'trialing'),

        managingChannel_id: user?.id,
        managingChannel_isSubscribe: isSubscribedUser,
        managingChannel_trialing: Boolean(
          user?.membershipStatus === 'trialing',
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileLoadProcessing]);
};

export default useTryLoginEventForGTM;
