import type {
  BaseBall,
  BasketBall,
  Billiard,
  Cricket,
  FootBall,
  Futsal,
  Golf,
  OverlayOption,
  PickleBall,
  Rugby,
  ScoreboardData,
  Soccer,
  Taekwondo,
  Tennis,
  scoreboardHasSet,
} from 'types/scoreboardData';

export const initialOverlayListData: OverlayOption[] = [
  {
    isDeleted: false,
    isShow: true,
    overlayIndex: 0,
    selectedThemeIndex: 0,
    selectedThemeImgURL: '',
    themeList: [{ theme: 'theme', type: 'SCORE_BOARD' as const }],
    title: 'Scoreboard',
  },
  {
    isDeleted: false,
    isShow: true,
    overlayIndex: 1,
    selectedThemeIndex: 0,
    selectedThemeImgURL: '',
    themeList: [{ theme: 'theme', type: 'MATCH' as const }],
    title: 'URL 2',
  },
];

export const initialBaseballData: BaseBall = {
  ball: '0',
  base1: false,
  base2: false,
  base3: false,
  inning: '1',
  isTop: true,
  out: '0',
  strike: '0',
  homeTeamInningScore: new Array(10).fill(0),
  awayTeamInningScore: new Array(10).fill(0),
  awayCurrentHitterId: -1,
  homeCurrentHitterId: -1,
  isIncludePitcherInBatterLineup: false,
};

export const initialBasketballData: BasketBall = {
  awayTeamFoul: '0',
  homeTeamFoul: '0',
  quarter: '1 Q',
  statusOf24Time: 'READY' as const,
  isProMode: true,
  isCourtChange: false,
  set24Time: 24,
};

export const initialBilliardData: Billiard = {
  inning: '0',
  set: '0',
};

export const initialCricketData: Cricket = {
  balls: '0',
  batsman1Hit: '0',
  batsman1Score: '0',
  batsman1Name: 'Batsman 1',
  batsman2Hit: '0',
  batsman2Score: '0',
  batsman2Name: 'Batsman 2',
  bowlerName: 'Bowler',
  bowlerOut: '0',
  bowlerScore: '0',
  homeTeamOut: '0',
  over: '0.0',
  target: '0',
  total: '0',
  isHomeTeamAttack: true,
  matchType: 'T20',
  numberOfOvers: 0,
  oversPerBowler: 0,
  powerPlayOvers: [],
  tossWinTeam: 'home',
  tossDecision: 'BAT',
};

export const initialPickleBallData: PickleBall = {
  awayServeCount: 1,
  homeServeCount: 2,
  awayPointRecord: [0, 0, 0],
  homePointRecord: [0, 0, 0],
  awayFoulWarningCount: 0,
  homeFoulWarningCount: 0,
  endPoint: 11,
  isDeuce: true,
  isServeAuto: true,
  serve: 'home1' as const,
  serveBegins: 'home' as const,
  set: 1,
};

export const initialRugbyData: Rugby = {
  previousHomeScore: 0,
  previousAwayScore: 0,
  isPlayerScoreRecordActive: false,
  isPlayerCardAndSubRecordActive: false,
  statusOfExtraTime: 'READY',
};

export const initialScoreboardHasSetData: scoreboardHasSet = {
  awayTeamSetScore: '0',
  homeTeamSetScore: '0',
  serve: 'home' as const,
  isServeShow: true,
  serveBegins: 'home' as const,
  serveSwitchPoint: 'scorer',
  endPoint: 21,
  isDeuce: true,
};

export const initialSoccerData: Soccer = {
  matchType: 'REGULAR_TIME' as const,
  isFirstHalf: true,
  homePenaltiesResult: [],
  awayPenaltiesResult: [],
  regularTimeDuration: 0,
  extraTimeDuration: 0,
};

export const initialFootballData: FootBall = {
  awayTeamTimeOut: '3',
  forthDown: '1',
  homeTeamTimeOut: '3',
  isHomePossession: true,
  yardToGo: '10',
};

const INITIAL_PENALTY_CONFIG = {
  id: '',
  name: '',
  number: null,
  penaltiesType: 'undo',
};

export const initialFutsalData: Futsal = {
  isPenalty: false,
  homePreviousScore: 0,
  awayPreviousScore: 0,
  isPlayerScoreRecordActive: false,
  isPlayerCardAndSubRecordActive: false,
  statusOfExtraTime: 'READY',
  homePenaltiesResult: Array(5).fill(INITIAL_PENALTY_CONFIG),
  awayPenaltiesResult: Array(5).fill(INITIAL_PENALTY_CONFIG),
  homeFoulCount: 0,
  awayFoulCount: 0,
};

export const initialGolfData: Golf = {
  selectedPlayerIndex: 0,
  players: [
    {
      holeScore: '0',
      playerName: 'Player 1',
      score: [
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
      ],
      scoreIn: '0',
      scoreOut: '0',
      scoreTotal: '0',
    },
    {
      holeScore: '0',
      playerName: 'Player 2',
      score: [
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
      ],
      scoreIn: '0',
      scoreOut: '0',
      scoreTotal: '0',
    },
    {
      holeScore: '0',
      playerName: 'Player 3',
      score: [
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
      ],
      scoreIn: '0',
      scoreOut: '0',
      scoreTotal: '0',
    },
    {
      holeScore: '0',
      playerName: 'Player 4',
      score: [
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
        '0',
      ],
      scoreIn: '0',
      scoreOut: '0',
      scoreTotal: '0',
    },
  ],
  par: [
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
  ],
  holeDistance: '450 M',
  holeNumber: '1',
  holePar: '3',
  isFirstHalf: true,
  parIn: '0',
  parOut: '0',
  parTotal: '0',
  playerShow: [true, true, true, true],
};

const initialTaekwondoData: Taekwondo = {
  homePenaltyCount: 0,
  awayPenaltyCount: 0,
  homeLatestScore: 0,
  awayLatestScore: 0,
  homePointRecord: [0, 0, 0],
  awayPointRecord: [0, 0, 0],
  matchType: 'bestOf3',
  pointGapWin: false,
  pointGap: 12,
  isRestTimeCountUp: false,
  pauseRestTime: 0,
  round: 1,
  setRestTime: 60,
  startRestTime: 0,
  statusOfRestTime: 'READY',
  timerType: 'MATCH',
};

export const initialTennisData: Tennis = {
  awayGame: 0,
  awayPoint: 0,
  awaySet: 0,
  awayRecord: new Array(6).fill(0).map(() => ({
    isTiebreak: false,
    game: 0,
    point: 0,
  })),
  currentSet: 1,
  homeGame: 0,
  homePoint: 0,
  homeSet: 0,
  homeRecord: new Array(6).fill(0).map(() => ({
    isTiebreak: false,
    game: 0,
    point: 0,
  })),
  isHomeServe: true,
  status: 'TITLE',
};

export const initialScoreboardData: ScoreboardData = {
  scoreBoard: {
    theme: 'universal',
    type: 'universal',
    controlTheme: '1',
  },

  common: {
    awayTeamAvatar: '',
    awayTeamColor: '',
    awayTeamFontSize: 5,
    awayTeamName: 'Away',
    awayTeamScore: '00',
    customCss: '',
    detailAddress: '',
    homeTeamAvatar: '',
    homeTeamColor: '',
    homeTeamFontSize: 5,
    homeTeamName: 'Home',
    homeTeamScore: '00',
    isHomeTeamLineUp: true,
    isImageShow: true,
    isSubscribed: false,
    leagueArea: '',
    matchName: 'Match name',
    matchNameFontSize: 3,
    matchNumber: 0,
    quarter: '1',
    playerFontSize: 2,
  },

  overlay: {
    overlayList: initialOverlayListData,
  },

  baseBall: initialBaseballData,

  basketBall: initialBasketballData,

  billiard: initialBilliardData,

  cricket: initialCricketData,

  footBall: initialFootballData,

  futsal: initialFutsalData,

  golf: initialGolfData,

  pickleball: initialPickleBallData,

  rugby: initialRugbyData,

  soccer: initialSoccerData,

  taekwondo: initialTaekwondoData,

  tennis: initialTennisData,

  scoreboardHasSet: initialScoreboardHasSetData,

  time: {
    additionalTime: 0,
    statusOfTime: 'READY',
    statusOfExtraTime: 'READY',
    isAdditionalTimerShow: false,
    isCountUp: false,
    isTimerShow: true,
    pauseMatchTime: 0,
    setTime: 0,
    startTime: 0,
    stopWatchStartTime: 0,
    matchType: 'REGULAR_TIME',
    isOverTime: false,
    isFirstHalf: true,
    isExtraHalf: false,
    isPlayerScoreRecordActive: false,
    isPlayerCardAndSubRecordActive: false,
  },

  matchData: {
    awayPlayers: '',
    homePlayers: '',
    matchStatus: 'schedule',
    matchDate: '',
    matchStartTime: '',
  },

  createdAt: 0,
};

export const initialBaseballHittingStats = {
  inningStats: [],
  atBat: 0,
  hit: 0,
  rbi: 0,
  onBase: 0,
  totalBase: 0,
  avg: 0,
  slg: 0,
  ops: 0,
  obp: 0,
  strikeOut: 0,
  notOut: 0,
  groundOut: 0,
  flyOut: 0,
  single: 0,
  double: 0,
  triple: 0,
  homerun: 0,
  error: 0,
  fourBall: 0,
  hitByPitch: 0,
  intentionalBB: 0,
  stolenBase: 0,
  sacrificeHit: 0,
  doublePlay: 0,
  fieldersChoice: 0,
};

export const initialBaseballPitcherStats = {
  inning: 0,
  hit: 0,
  tbf: 0,
  homerun: 0,
  fourBall: 0,
  hbp: 0,
  strikeOut: 0,
  wildPitch: 0,
  runs: 0,
  earnedRuns: 0,
  pitchCount: 0,
};
